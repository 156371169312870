section.page404{
  padding-top: 57px;
  .wide{
    .row{
      .col-md-12{
        a.logo{
          display: block;
          opacity: .2;
          max-width: 311px;
          margin-bottom: 16px;
          height: 49px;
          @media (max-width: 350px){
            max-width: 100%;
          }
          img{
            max-width: 100%;
            max-height: 100%;
          }
        }
        a.phone_num{
          display: block;
          color: #000;
          margin-bottom: 12px;
          letter-spacing: .7px;
        }
        a.mail_address{
          display: block;
          color: #0f81b2;
          text-decoration: underline #0f81b2;
          margin-bottom: 64px;
          letter-spacing: .7px;
          h3{
            color: #0f81b2;
          }
        }
        img.not_found{
          display: block;
          max-width: 487px;
          height: 192px;
          margin: 0 0 219px;
          @media (max-width: 570px){
            max-width: 100%;
            height: auto;
            margin-bottom: 90px;
          }
        }
      }
    }
  }
  footer.page_404{
    padding-bottom: 36px;
    nav{
      display: inline-block;
      border-left: 2px solid rgba(0,0,0,.2);
      padding-left: 12px;
      padding-right: 39px;
      min-width: 149px;
      @media (max-width: 990px){
        padding-right: 25px;
        min-width: 125px;
        &:last-of-type{
          padding-right: 0;
        }
      }
      @media (max-width: 768px){
        margin-bottom: 15px;
      }
      @media (max-width: 400px){
        margin-bottom: 0;
        display: block;
        padding-bottom: 10px;
      }
      a{
        display: block;
        color: #0f81b2;
        text-decoration: underline #0f81b2;
        margin-bottom: 15px;
        @media (max-width: 400px){
          margin-bottom: 10px;
        }
        &:last-of-type{
          margin-bottom: 0;
          h3{
            margin-bottom: 0;
          }
        }
        h3{
          color: #0f81b2;
          line-height: 29px;
        }
      }
      h3{
        margin-bottom: 15px;
        @media (max-width: 400px){
          margin-bottom: 10px;
        }
      }
    }
  }
}