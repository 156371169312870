section.blog{
  padding-top: 40px;
  margin-bottom: 67px;
  .wide{
    .row{
      h1{
        margin-bottom: 25px;
      }
      .col-md-10{
        @media (max-width: 990px){
          flex: 0 0 100%;
          max-width: 100%;
        }
        form{
          margin-top: 116px;
          height: 81px;
          width: 100%;
          @media (max-width: 1230px){
            width: 600px;
            height: 60px;
          }
          @media (max-width: 990px){
            width: 100%;
            margin-top: 50px;
          }
          @media (max-width: 768px){
            height: 35px;
          }
          input{
            width: 73%;
            display: inline-block;
            outline: none;
            font-size: 38px;
            border: 4px solid rgba(0,0,0, .2);
            box-shadow: inset 0 2px 0 rgba(0, 0, 0, 0.3);
            //max-width: 615px;
            height: 100%;
            padding-left: 82px;
            background: url(../img/pics/search_icon.png) 20px 50% no-repeat;
            background-size: 37px 51px;
            color: #444;
            @media (max-width: 1230px){
              font-size: 20px;
              background-size: 20px 29px;
              padding-left: 50px;
              background-position: 17px 50%;
            }
            @media (max-width: 768px){
              font-size: 17px;
              line-height: 17px;
              background-size: 17px 22px;
              padding-left: 36px;
              background-position: 8px 50%;
              border-width: 1px;
              width: calc(100% - 85px);
            }
            &::placeholder{
              opacity: 0.4;
              color: #000000;
            }
          }
          button{
            display: inline-block;
            width: 20.5%;
            height: 81px;
            background-color: #ffde00;
            color: #000000;
            font-weight: 700;
            font-size: 40px;
            line-height: 40px;
            text-align: center;
            border: none;
            margin-left: -8px;
            position: relative;
            top: .5px;
            outline: none;
            @media (max-width: 1230px){
              height: 60px;
              line-height: 24px;
              font-size: 24px;
            }
            @media (max-width: 768px){
              height: 35px;
              width: 85px;
              font-size: 18px;
              line-height: 17px;
            }
          }
        }
      }
      .head{
        margin-top: 72px;
        &:first-of-type{
          margin-top: 0;
        }
      }
      .wrapper{
        max-width: 893px;
        border-bottom: 2px solid #eee;
        margin: 99px auto 20px;
        @media (max-width: 768px){
          margin: 60px auto 10px;
        }
        @media (max-width: 539px){
          display: none;
        }
        img{
          display: inline-block;
          margin-bottom: 10px;
          vertical-align: bottom;
          max-width: 113px;
          max-height: 86px;
          margin-right: 20px;
          @media (max-width: 990px){
            margin-right: 10px;
            max-width: 82px;
          }
          @media (max-width: 768px){
            max-width: 45px;
            max-height: 50px;
            margin-top: 30px;
          }
        }
        .a_container{
          margin-top: 1px;
          margin-right: 12px;
          max-width: 236px;
          float: right;
          a{
            display: block;
            &.link{
              color: #0587ba;
              font-size: 14px;
              font-weight: 700;
              text-decoration: underline;
              margin-bottom: 14px;
            }
            &.button{
              color: #000000;
              height: 48px;
              background-color: #ffde00;
              width: 100%;
              font-size: 18px;
              font-weight: 700;
              line-height: 46px;
              text-decoration: none;
              text-align: center;
              margin-bottom: 0;
              @media (max-width: 768px){
                height: 42px;
                line-height: 40px;
              }
            }
          }
        }
      }
    }
  }
}