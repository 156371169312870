section.sticky{
  display: none;
  position: fixed;
  background-color: #ffffff;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 110px;
  &.active{
    display: block;
  }
  .container_for_page{
    box-shadow: 0 4px 46px rgba(0, 0, 0, 0.75);
  }
  .wide{
    .row{
      .col-md-12{
        .img_container{
          display: inline-block;
          vertical-align: top;
          margin: 13px 15px 14px 0;
          max-width: 55px;
          height: 83px;
          img{
            max-width: 100%;
            max-height: 100%;
          }
        }
        .data{
          display: inline-block;
          max-width: 50%;
          h3{
            margin-top: 9px;
            letter-spacing: .64px;
            margin-bottom: 8px;
          }
          .to_cart{
            width: 128px;
            max-width: 100%;
            margin-bottom: 0;
            padding-top: 0;
            padding-bottom: 0;
            border: none;
            border-radius: 0;
            height: 42px;
            color: #000000;
            font-weight: 800;
            outline: none;
            text-align: left;
            padding-left: 16px;
            background-color: #ffde00;
            position: relative;
            h3{
              font-weight: 800;
              line-height: 24px;
              display: inline-block;
            }
            &::after{
              content: "";
              background: url(../img/pics/cart_icon_black.png) no-repeat;
              width: 24px;
              height: 23px;
              display: inline-block;
              margin-left: 8%;
              vertical-align: sub;
              background-size: contain;
            }
            &::before {
              content: "";
              position: absolute;
              top: 0;
              right: -14px;
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 21px 0 21px 15px;
              border-color: transparent transparent transparent #ffde00;
            }
            &:focus{
              border: none;
              outline: none;
              box-shadow: none;
            }
          }
        }
      }
    }
  }
}

section.goods{
  margin-top: 46px;
  .wide{
    .row{
      align-items: flex-start;
      &.info{
        .col-md-10{
          @media (max-width: 990px){
            max-width: 100%;
            flex: 0 0 100%;
          }
        }
      }
      h1{
        margin-bottom: 44px;
      }
      .img_container{
        max-width: 166px;
        width: 100%;
        max-height: 266px;
        vertical-align: top;
        margin-right: 57px;
        margin-bottom: 30px;
        display: inline-block;
        @media (max-width: 768px){
          max-width: 100px;
          height: auto;
          margin-right: 30px;
        }
        @media (max-width: 490px){
          margin-right: 12px;
        }
        @media (max-width: 360px){
          display: block;
        }
        img{
          max-height: 100%;
          max-width: 100%;
          width: 100%;
          height: 100%;
        }
      }
      .text{
        display: inline-block;
        max-width: calc(100% - 269px);
        @media (max-width: 768px){
          max-width: calc(100% - 135px);
        }
        @media (max-width: 768px){
          max-width: calc(100% - 117px);
        }
        @media (max-width: 360px){
          display: block;
          max-width: 100%;
        }
        h3{
          margin-bottom: 20px;
        }
      }
      .tech_data{
        margin-top: 49px;
        max-width: 723px;
        margin-bottom: 117px;
        display: block;
        @media (max-width: 490px){
          margin-bottom: 50px;
        }
        p.head{
          color: #000000;
          font-size: 38px;
          font-weight: 700;
          line-height: 52px;
          letter-spacing: 0.95px;
          display: block;
          border-bottom: 2px solid #aaa;
          margin-bottom: 23px;
          @media (max-width: 768px){
            font-size: 30px;
          }
          @media (max-width: 490px){
            font-size: 24px;
            line-height: 30px;
            margin-bottom: 10px;
          }
        }
        .column{
          vertical-align: top;
          display: inline-block;
          @media (max-width: 768px){
            display: block;
          }
          &:first-of-type{
            width: 54%;
            margin-right: .8%;
            @media (max-width: 768px){
              width: 100%;
            }
          }
          &:last-of-type{
            width: 44%;
            @media (max-width: 768px){
              width: 100%;
            }
            .first{
              width: 54%;
              @media (max-width: 768px){
                width: 67%;
              }
              @media (max-width: 430px){
                width: 100%;
              }
            }
          }
          .item{
            display: block;
            margin-bottom: 0;
            @media (max-width: 430px){
              margin-bottom: 10px;
            }
          }
          .first{
            background: linear-gradient(#ccc 0, white 1px);
            background-repeat: repeat-x;
            background-size: 2px 2px;
            background-position: 0 80%;
            width: 67%;
            display: inline-block;
            @media (max-width: 430px){
              width: 100%;
            }
          }
          p{
            color: #000000;
            line-height: 32px;
            background: white;
            margin-bottom: 0;
            display: inline-block;
            @media (max-width: 430px){
              line-height: 24px;
            }
          }
        }
      }

      .col-md-2{
        padding-left: 5px;
        @media (max-width: 990px){
          max-width: 100%;
          flex: 0 0 100%;
          padding-left: 15px;
        }
        @media (max-width: 768px){
          padding-left: 10px;
        }
        .data{
          margin-bottom: 31px;
          @media (max-width: 990px){
            display: inline-block;
            width: 49%;
            vertical-align: top;
          }
          @media (max-width: 430px){
            display: block;
          }
          p{
            margin-bottom: 0;
            letter-spacing: .3px;
            color: #000000;
            a{
              text-decoration: underline #0587ba;
            }
          }
        }
        .price{
          @media (max-width: 990px){
            display: inline-block;
            max-width: 50%;
          }
          @media (max-width: 430px){
            display: block;
          }
          p.last_price{
            padding: 0 18px;
            position: relative;
            display: inline-block;
            color: #000000;
            margin-bottom: 25px;
            font-size: 22px;
            line-height: 0.01px;
            @media (max-width: 990px){
              margin-right: 12px;
            }
            &::after{
              content: "";
              width: 100%;
              height: 2px;
              background: #ff2727;
              box-shadow: 0 1px 0 #ffffff;
              position: absolute;
              left: 0;
              top: 0;
            }
          }
          .to_cart{
            width: 180px;
            max-width: 100%;
            margin-bottom: 16px;
            padding-top: 14px;
            padding-bottom: 0;
            border: none;
            border-radius: 0;
            height: 58px;
            color: #000000;
            font-weight: 800;
            outline: none;
            text-align: left;
            padding-left: 19px;
            font-size: 28px;
            line-height: 28px;
            background-color: #ffde00;
            position: relative;
            @media (max-width: 990px){
              height: 42px;
              padding-top: 7px;
            }
            &::after{
              content: "";
              background: url(../img/pics/cart_icon_black.png) no-repeat;
              width: 32px;
              height: 31px;
              display: inline-block;
              margin-left: 16%;
              vertical-align: sub;
              background-size: contain;
              @media (max-width: 990px){
                width: 25px;
                height: 25px;
                position: relative;
                top: -1px;
              }
            }
            &:focus{
              border: none;
              outline: none;
              box-shadow: none;
            }
          }
          .amount{
            margin-bottom: 33px;
            opacity: 0.3;
            //max-width: 115px;
            .item-quantity{
              width: 42%;
              height: 48px;
              border: 1px solid #c1c1c1;
              background-color: #ffffff;
              text-align: center;
              font-size: 28px;
              font-weight: 800;
              display: inline-block;
              @media (max-width: 990px){
                height: 40px;
                width: 30%;
              }
            }
            .increase, .decrease{
              width: 21.5%;
              height: 48px;
              display: inline-block;
              background-color: #aaa;
              vertical-align: top;
              border: none;
              outline: none;
              position: relative;
              color: #000000;
              font-size: 28px;
              line-height: 28px;
              font-weight: 700;
              cursor: pointer;
              @media (max-width: 990px){
                height: 40px;
              }
            }
            .increase::after{
              content: "";
              position: absolute;
              top: 0;
              right: -14px;
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 24px 0 24px 15px;
              border-color: transparent transparent transparent #aaa;
              @media (max-width: 990px){
                border-width: 20px 0 20px 15px;
              }
            }
            .decrease{
              margin-left: 10px;
            }
            .decrease::before{
              content: "";
              position: absolute;
              top: 0;
              left: -15px;
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 24px 15px 24px 0;
              border-color: transparent #aaa transparent transparent;
              @media (max-width: 990px){
                border-width: 20px 15px 20px 0;
              }
            }
          }
        }

        .item{
          margin-bottom: 34px;
          p{
            color: #000000;
            margin-bottom: 18px;
            &:last-of-type{
              @media (max-width: 990px){
                br{
                  display: none;
                }
              }
            }
            a.visa, a.mc{
              display: inline-block;
              position: relative;
              top: -2px;
              margin: 0 0;
            }
          }
          &:last-of-type{
            p{
              position: relative;
              margin-bottom: 10px;
              padding-left: 38px;
              @media (max-width: 990px){
                //margin-bottom: 35px;
                width: 32%;
                display: inline-block;
                vertical-align: top;
              }
              @media (max-width: 490px){
                width: 100%;
                display: block;
              }
              &::before{
                content: "";
                position: absolute;
                top: 4px;
                left: 1px;
              }
              &.available::before{
                background: url(../img/pics/box_delivery.png) no-repeat;
                width: 30px;
                height: 30px;
              }
              &.lider::before{
                background: url(../img/pics/flag_icon.png) no-repeat;
                width: 29px;
                height: 34px;
              }
              &.ask_stroy_guru::before{
                background: url(../img/pics/man_icon.svg) no-repeat;
                width: 32px;
                height: 35px;
                background-size: contain;
              }
              &.ask_stroy_guru{
                a{
                  display: block;
                }
              }
            }
          }
        }
      }
      &.calc{
        margin-bottom: 77px;
        .calc_form{
          max-width: 48%;
          flex: 0 0 48%;
          @media (max-width: 990px){
            max-width: 100%;
            flex: 0 0 100%;
            margin-top: 50px;
          }
        }
        form{
          background-color: rgba(173,171,175,.2);
          border-radius: 40px;
          max-width: 520px;
          width: 100%;
          padding: 46px 20px 60px;
          h3{
            margin-bottom: 23px;
          }
          select{
            box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.3);
            border: 1px solid #c1c1c1;
            padding: 0 30px 0 10px;
            background-color: #ffffff;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            background-image: url(../img/pics/arrow_down.png);
            background-repeat: no-repeat;
            background-size: 12px 7px;
            background-position: calc(100% - 10px) 50%;
            outline: none;
          }
          select.type{
            width: 100%;
            height: 40px;
            color: #000000;
            font-size: 22px;
            font-weight: 700;
            line-height: 30px;
            margin-bottom: 35px;
            @media (max-width: 420px){
              font-size: 18px;
            }
          }
          .form-group{
            margin-bottom: 9px;
            p, input{
              display: inline-block;
            }
            .help-block{
              display: block;
            }
            p{
              color: #000000;
              font-size: 16px;
              margin-bottom: 0;
              @media (max-width: 420px){
                font-size: 14px;
              }
              &:first-of-type{
                width: 168px;
                @media (max-width: 420px){
                  width: 158px;
                }
              }
            }
            input{
              width: 47px;
              height: 29px;
              box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.3);
              border: 1px solid #c1c1c1;
              background-color: #ffffff;
              font-size: 25px;
              text-align: center;
              margin: 0 2px;
              outline: none;
              color: #000000;
              &::placeholder{
                color: #000000;
              }
            }
            &.product_select{
              margin-bottom: 7px;
              &:nth-child(3){
                margin-bottom: 21px;
              }
              p{
                width: 75px;
                @media (max-width: 420px){
                  width: 75px;
                }
              }
              select{
                width: calc(100% - 79px);
                color: #000000;
                font-size: 16px;
                height: 29px;
              }
            }
          }
          hr{
            display: block;
            width: 100%;
            height: 2px;
            border: none;
            background: #000;
            opacity: .5;
            margin-top: 55px;
            margin-bottom: 10px;
          }
          p.result{
            color: #010001;
            font-size: 72px;
            font-weight: 800;
            line-height: 66px;
            display: inline-block;
            @media (max-width: 420px){
              font-size: 48px;
            }
          }
          p.details{
            color: #000000;
            display: inline-block;
            margin-left: 10px;
            line-height: 18px;
            span{
              font-weight: 800;
            }
          }
          button{
            display: block;
            height: 55px;
            margin-top: 8px;
            width: 100%;
            outline: none;
            box-shadow: 0 11px 40px #ffffff;
            background-color: #ffde00;
            border: none;
            text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
            color: #000000;
            font-size: 16px;
            font-weight: 700;
            &::afteR{
              content: "";
              background: url(../img/pics/cart_icon_small.png) no-repeat;
              width: 16px;
              height: 15px;
              display: inline-block;
            //background-size: contain;
              margin-left: 2px;
              position: relative;
              left: 10px;
            }
          }
        }
      }
      .cheaper{
        filter: grayscale(100%);
        opacity: 0.6;
        @media (max-width: 990px){
          max-width: 100%;
          flex: 0 0 100%;
          margin-top: 30px;
        }
        h1{
          margin-bottom: 23px;
        }
        .container_for_deal{
          padding-bottom: 21px;
          border-bottom: 5px solid black;
          .item{
            display: inline-block;
            width: 32%;
            margin-right: 1%;
            position: relative;
            @media (max-width: 430px){
              width: 100%;
              display: block;
              margin-bottom: 20px;
            }
            &::after{
              content: "+";
              position: absolute;
              top: 21px;
              right: 18px;
              font-size: 54px;
              line-height: 54px;
              font-weight: 700;
              @media (max-width: 490px){
                right: -5px;
              }
              @media (max-width: 430px){
                right: 20%;
              }
            }
            &:last-of-type{
              margin-right: 0;
              &::after{
                display: none;
              }
            }
            .img-container{
              display: block;
              max-height: 90px;
              height: 90px;
              margin-bottom: 19px;
              img{
                max-height: 100%;
              }
            }
            .name{
              text-decoration: underline solid #0587ba;
              margin-bottom: 10px;
              line-height: 20px;
              display: block;
              font-size: 14px;
              @media (max-width: 470px){
                font-size: 10px;
                line-height: 14px;
              }
              span{
                letter-spacing: 1.4px;
              }
            }
            .price{
              color: #000000;
              font-weight: 700;
              line-height: 14px;
              &::after{
                content: "₽";
                display: inline-block;
                margin-left: 1px;
              }
              @media (max-width: 470px){
                font-size: 12px;
                line-height: 12px;
              }
            }
          }
        }
        p.last_price{
          margin-top: 4px;
          font-size: 28px;
          font-weight: 700;
          letter-spacing: 0.7px;
          line-height: 42px;
          padding: 0 18px;
          position: relative;
          display: inline-block;
          color: #000000;
          margin-bottom: 13px;
          &::after{
            content: "";
            width: 100%;
            height: 2px;
            background: #ff2727;
            box-shadow: 0 1px 0 #ffffff;
            position: absolute;
            left: 0;
            top: 18px;
          }
        }
        .to_cart{
          display: block;
          height: 88px;
          margin-top: 8px;
          padding-top: 22px;
          padding-bottom: 10px;
          width: 100%;
          outline: none;
          box-shadow: 0 11px 40px #ffffff;
          background-color: #ffde00;
          border: none;
          text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
          color: #000000;
          font-size: 28px;
          line-height: 30px;
          font-weight: 700;
          @media (max-width: 490px){
            height: 58px;
            padding-top: 7px;
            font-size: 18px;
          }
          &::afteR{
            content: "";
            background: url(../img/pics/cart_icon_black.png) no-repeat;
            width: 32px;
            height: 31px;
            display: inline-block;
            margin-left: 29px;
            position: relative;
            left: -10px;
            top:5px;
            @media (max-width: 490px){
              width: 25px;
              height: 25px;
              margin-left: 15px;
              position: relative;
              top: 6px;
              background-size: contain;
            }
          }
        }
        .amount{
          margin-top: 24px;
          text-align: center;
          margin-bottom: 35px;
          opacity: 0.3;
        //max-width: 115px;
          .item-quantity{
            width: 78px;
            height: 68px;
            border: 1px solid #c1c1c1;
            background-color: #ffffff;
            text-align: center;
            font-size: 28px;
            font-weight: 800;
            display: inline-block;
            @media (max-width: 490px){
              height: 42px;
            }
          }
          .increase, .decrease{
            width: 39px;
            height: 68px;
            display: inline-block;
            background-color: #aaa;
            vertical-align: top;
            border: none;
            outline: none;
            position: relative;
            color: #000000;
            font-size: 28px;
            font-weight: 700;
            cursor: pointer;
            @media (max-width: 490px){
              height: 42px;
            }
          }
          .increase::after{
            content: "";
            position: absolute;
            top: 0;
            right: -18px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 34px 0 34px 20px;
            border-color: transparent transparent transparent #aaa;
            @media (max-width: 490px){
              border-width: 21px 0 21px 15px;
              right: -14px;
            }
          }
          .decrease{
            margin-left: 10px;
          }
          .decrease::before{
            content: "";
            position: absolute;
            top: 0;
            left: -19px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 34px 20px 34px 0;
            border-color: transparent #aaa transparent transparent;
            @media (max-width: 490px){
              border-width: 21px 15px 21px 0;
              left: -14px;
            }
          }
        }
      }

      &.comparison{
        margin-top: 100px;
        .table_container{
          max-width: 100%;
          overflow-x: auto;
          margin-bottom: 75px;
          width: 100%;
          table{
            min-width: 961px;
            display: table;
            width: 100%;
            border: none;
            th{
              padding-left: 10px;
              height: 140px;
              max-width: 104px;
              &:first-of-type{
                width: 20%;
                max-width: 250px;
              }
              .img_container{
                max-width: 100%;
                height: 141px;
                max-height: 141px;
                margin: 0;
                margin-bottom: 20px;
                img{
                  max-height: 100%;
                  width: auto;
                }
              }
              p.name{
                font-size: 14px;
                font-weight: 700;
                line-height: 20px;
                color: #000000;
                margin-bottom: 9px;
              }
            }
            td{
              color: #000000;
              padding-left: 10px;
              font-size: 18px;
              &:first-of-type{
                width: 25.2%;
                font-size: 14px;
                font-weight: 700;
                line-height: 30px;
                padding-left: 0;
                //padding-right: 15px;
              }
            }
          }
        }
      }
    }
  }
}