*{box-sizing:border-box}

.container_for_page{
    width: 100%;
    max-width: 1280px;
    //padding: 0 20px;
    margin: 0 auto;
    box-shadow: 0 0 20px rgba(0,0,0,.5);
}

@media (min-width: 1210px){
    .container.wide {
        max-width: 1210px;
    }
}
@media (min-width: 991px){
    .container.wide {
        max-width: 100%;
    }
}

@media (max-width: 768px){
    .container.wide {
        padding-left: 10px;
        padding-right: 10px;
        .row{
            margin-right: -10px;
            margin-left: -10px;
        }
        .col-md-1, .col-md-2, .col-md-3,.col-md-4,.col-md-5,.col-md-6,.col-md-7,.col-md-8,.col-md-9,.col-md-10,.col-md-11,.col-md-12{
            padding-left: 10px;
            padding-right: 10px;
        }
    }
}

.banner{
    display: block;
    margin: 0 auto;
    //max-width: 1280px;
    background-image: linear-gradient(to right, #f0dfc6 0%, #d9ccba 100%);
    width: 100%;
    @media (max-width: 768px){
        display: none;
    }
    img{
        width: 100%;
        max-width: 1280px;
    }
}

h1{
    color: #000000;
    font-size: 54px;
    font-weight: 700;
    display: inline-block;
    letter-spacing: 1.35px;
    border-bottom: 1px solid rgba(0,0,0,.2);
    margin-bottom: 54px;
    &.mb23 {
        margin-bottom: 23px;
    }
    @media (max-width: 990px){
        font-size: 35px;
        line-height: 35px;
        letter-spacing: normal;
        margin-bottom: 34px;
    }
    @media (max-width: 768px){
        font-size: 27px;
        line-height: 28px;
    }
    @media (max-width: 470px){
        font-size: 22px;
        line-height: 22px;
        margin-bottom: 20px;
        &.mb23 {
            margin-bottom: 15px;
        }
    }
    &.no_underline{
        border-bottom: none;
        //letter-spacing: normal;
    }
}

h2{
    color: #000000;
    font-size: 35px;
    font-weight: 400;
    letter-spacing: 0.7px;
    @media (max-width: 990px){
        font-size: 27px;
        line-height: 28px;
        letter-spacing: normal;
        margin-bottom: 34px;
    }
    @media (max-width: 768px){
        font-size: 22px;
        line-height: 22px;
    }
    @media (max-width: 470px){
        font-size: 20px;
        line-height: 20px;
        margin-bottom: 20px;
    }
}

h3{
    color: #000000;
    font-size: 22px;
    font-weight: 700;
    line-height: 34px;
    @media (max-width: 990px){
        font-size: 18px;
        line-height: 23px;
    }
    @media (max-width: 470px){
        font-size: 15px;
        line-height: 18px;
    }
    &.light_vers{
        font-weight: 400;
    }
}

a{
    color: #0587ba;
}

button{
    cursor: pointer;
}

.container_for_slider{
    position: relative;
    @media (max-width: 1240px){
        padding: 0 30px;
    }
    @media (max-width: 990px){
        padding: 0;
    }
    .slick-prev, .slick-next{
        position: absolute;
        width: 20px;
        height: 41px;
        top: 100px;
        background: transparent;
        border-radius: 0;
        outline: none;
        border: none;
    }
    .slick-prev{
        left: -30px;
        background-image: url(../img/pics/arrow_slick_prev.png);
        background-position: 100% 50%;
        background-repeat: no-repeat;
        transition: all .5s ease;
        @media (max-width: 1240px){
            left: 10px;
        }
        &:hover{
            background-position: 0% 50%;
        }
    }
    .slick-next{
        right: -30px;
        background-image: url(../img/pics/arrow_slick_next.png);
        background-position: 0% 50%;
        background-repeat: no-repeat;
        transition: all .5s ease;
        @media (max-width: 1240px){
            right: 10px;
        }
        &:hover{
            background-position: 100% 50%;
        }
    }
}

.more_there{
    //margin-top: 25px;
    //float: right;
    .img_container{
        display: inline-block;
        margin-right: 6px;
        a{
            display: inline-block;
            line-height: 22px;
            width: 25px;
            height: 39px;
            img{
                max-width: 100%;
                max-height: 100%;
            }
        }
    }
    p{
        vertical-align: sub;
        display: inline-block;
        a{
            display: inline-block;
            color: #000000;
            font-size: 20px;
            border-bottom: 1px solid rgba(0,0,0,.2);
            text-decoration: none;
            margin-right: 25px;
            position: relative;
            &::after{
                content: "→";
                position: absolute;
                top: 0;
                right: -25px;
            }
        }
    }
}

.way_to_page{
    margin-top: 4px;
    a.way{
        font-size: 14px;
        letter-spacing: .5px;
        line-height: 14px;
        display: inline-block;
        text-decoration: underline;
        &::after{
            content: "···";
            font-size: 14px;
            color: #000000;
            display: inline-block;
            margin-left: 4px;
        }
        &:last-of-type{
            color: #000000;
            text-decoration: none;
            cursor: auto;
            &::after{
                display: none;
            }
        }
    }
}

.sale {
    max-width: 100%;
    &.gray_item{
        p.head{
            background-color: #eceaec;
            color: #000000;
        }
    }
    a.head_promo{
        display: block;
        margin-bottom: 24.5px;
    }
    p.head {
        margin-top: 0;
        display: inline-block;
        padding: 0 10px;
        margin-right: -4px;
        margin-bottom: 0;
        color: #ffffff;
        background-color: #ff1800;
        font-size: 53px;
        font-weight: 700;
        letter-spacing: 1.35px;
        line-height: 58px;
        @media (max-width: 420px) {
            font-size: 50px;
        }
        &.smaller{
            letter-spacing: -1px;
            @media (max-width: 1100px){
                letter-spacing: -2.7px;
            }
            @media (max-width: 500px){
                font-size: 41px;
                letter-spacing: -0.7px;
            }
        }
        span {
            font-weight: 400;
            font-size: 22px;
            line-height: 46px;
            display: block;
        }
    }
    p {
        padding: 0 10px;
        margin-bottom: 0;
        line-height: 20px;
        margin-top: 10px;
        strong {
            display: block;
        }
    }
}

.col-md-12.enter {
    margin-top: 45px;
    margin-bottom: 88px;
    a {
        width: 173px;
        position: relative;
        display: block;
        color: #000000;
        font-size: 28px;
        font-weight: 700;
        line-height: 64px;
        text-align: center;
        text-decoration: none;
        height: 66px;
        margin-left: calc(50% - 124px);
        background-color: #ffde00;
        @media (max-width: 768px) {
            margin-left: 0;
        }
        @media (max-width: 480px) {
            margin-bottom: 30px;
        }
        &::after {
            content: "Войдите в личный            кабинет чтобы            увидеть более точные            рекомендации";
            width: 150px;
            height: 100%;
            position: absolute;
            top: 0;
            right: -168px;
            color: #000000;
            font-size: 14px;
            line-height: 16px;
            text-align: left;
            font-weight: 400;
            letter-spacing: 0.14px;
            @media (max-width: 480px) {
                width: 94vw;
                top: 70px;
                left: 0;
                right: auto;
            }
        }
    }
}

