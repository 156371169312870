section.search{
  margin-bottom: 124px;
  padding-top: 50px;
  @media (max-width: 990px){
    margin-bottom: 70px;
  }
  @media (max-width: 768px){
    margin-bottom: 40px;
    padding-top: 30px;
  }
  .wide{
    .row{
      .col-md-8{
        @media (max-width: 990px){
          flex: 0 0 100%;
          max-width: 100%;
        }
        form{
          height: 81px;
          width: 835px;
          @media (max-width: 1230px){
            width: 600px;
            height: 60px;
          }
          @media (max-width: 990px){
            width: 100%;
          }
          @media (max-width: 768px){
            height: 35px;
          }
          input{
            width: 76%;
            display: inline-block;
            outline: none;
            font-size: 38px;
            border: 4px solid rgba(0,0,0, .2);
            box-shadow: inset 0 2px 0 rgba(0, 0, 0, 0.3);
            max-width: 615px;
            height: 100%;
            padding-left: 82px;
            background: url(../img/pics/search_icon.png) 20px 50% no-repeat;
            background-size: 37px 51px;
            color: #444;
            @media (max-width: 1230px){
              font-size: 20px;
              background-size: 20px 29px;
              padding-left: 50px;
              background-position: 17px 50%;
            }
            @media (max-width: 768px){
              font-size: 17px;
              line-height: 17px;
              background-size: 17px 22px;
              padding-left: 36px;
              background-position: 8px 50%;
              border-width: 1px;
              width: calc(100% - 85px);
            }
            &::placeholder{
              opacity: 0.4;
              color: #000000;
            }
          }
          button{
            display: inline-block;
            width: 23.5%;
            height: 81px;
            background-color: #ffde00;
            color: #000000;
            font-weight: 700;
            font-size: 40px;
            line-height: 40px;
            text-align: center;
            border: none;
            margin-left: -8px;
            position: relative;
            top: .5px;
            outline: none;
            @media (max-width: 1230px){
              height: 60px;
              line-height: 24px;
              font-size: 24px;
            }
            @media (max-width: 768px){
              height: 35px;
              width: 85px;
              font-size: 18px;
              line-height: 17px;
            }
          }
        }
      }
      .col-md-4{
        @media (max-width: 990px){
          flex: 0 0 100%;
          max-width: 100%;
          margin-top: 20px;
        }
        @media (max-width: 768px){
          display: none;
        }
        .send_me_a_photo{
          float: right;
          @media (max-width: 1230px){
            float: none;
          }
        }
        img{
          display: inline-block;
          vertical-align: top;
          margin-right: 11px;
          margin-top: -4px;
        }
        p{
          display: inline-block;
          max-width: 164px;
          margin-bottom: 0;
          color: #000000;
          font-size: 16px;
          line-height: 19px;
          @media (max-width: 990px){
            max-width: calc(100% - 90px);
            margin-top: 20px;
          }
          a{
            color: #0a84b6;
            text-decoration: underline;
          }
        }
      }
    }
  }
}