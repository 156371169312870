header.black{
  background-color: #413f43;
  min-height: 43px;
  .col-md-2{
    text-align: right;
    @media (max-width: 990px){
      flex: 0 0 45px;
      max-width: 45px;
    }
  }
  .col-md-10{
    @media (max-width: 990px){
      flex: 0 0 calc(100% - 45px);
      max-width: calc(100% - 45px);
    }
  }
  nav{
    @media (max-width: 990px){
      display: inline-block;
    }
    a{
      padding: 13px 11px 9px;
      letter-spacing: 1.1px;
      text-decoration: none;
      @media (max-width: 1040px){
        padding: 12px 5px;
        //margin-right: 10px;
      }
      @media (max-width: 990px){
        padding: 12px 8px;
        //margin-right: 16px;
      }
      @media (max-width: 375px){
        padding: 12px 5px;
        //margin-right: 10px;
      }
      &:hover{
        background: rgba(255,255,255,.2);
      }
      &.active{
        background: rgba(255,255,255,.2);
      }
    }
  }
  .burger_item{
    display: none;
    width: 20px;
    height: 16px;
    position: relative;
    top: 3px;
    margin-right: 16px;
    background: url(../img/pics/burger_icon.png) no-repeat;
    @media (max-width: 990px){
      display: inline-block;
      &.open{
        nav{
          display: block;
          opacity: 1;
          z-index: 999;
        }
      }
    }
    @media (max-width: 341px){
      margin-right: 10px;
    }
    nav{
      transition: all .5s ease;
      //display: none;
      opacity: 0;
      position: fixed;
      padding-top: 50px;
      top: 0;
      lefT: 0;
      width: 100vw;
      z-index: -999;
      background-color: #413f43;
      min-height: 100vh;
      a{
        margin: 0 auto 20px;
        text-align: center;
        font-size: 16px;
        display: block;
        max-width: 130px;
        &:first-of-type{
          max-width: 260px;
        }
      }
      a.close{
        text-transform: none;
        border-radius: 50%;
        border: 1px solid white;
        position: absolute;
        top: 20px;
        right: 20px;
        margin-bottom: 0;
        font-size: 15px;
        line-height: 10px;
        text-align: center;
        color: white;
        padding: 6px 8px 9px;
      }
    }
  }
  a{
    color: #ffffff;
    font-size: 11px;
    line-height: 20px;
    display: inline-block;
    transition: all .5s ease;
    text-transform: uppercase;
    @media (max-width: 375px){
      font-size: 10px;
    }
    &.guru{
      position: relative;
      padding-left: 35px;
      @media (max-width: 990px){
        padding-left: 8px;
      }
      &::before{
        content: "";
        background: url(../img/pics/guru_icon.png) no-repeat;
        width: 19px;
        height: 16px;
        position: absolute;
        top: 14px;
        lefT: 8px;
        @media (max-width: 990px){
          display: none;
        }
      }
    }
    &.enter{
      text-decoration: underline;
      text-transform: none;
      white-space: nowrap;
      margin-top: 5px;
      font-size: 12px;
      margin-right: 0;
      @media (max-width: 990px){
        width: 14px;
        height: 28px;
        overflow: hidden;
        position: relative;
        color: transparent;
        &:hover{
          text-shadow: none;
        }
      }
      &::after{
        content: "";
        background: url(../img/pics/enter_icon.png) no-repeat;
        width: 14px;
        height: 21px;
        display: inline-block;
        margin-left: 8px;
        position: relative;
        top: 3px;
        @media (max-width: 990px){
          position: absolute;
          top: 5px;
          left: 0;
          margin-left: 0;
        }
      }
    }
    &.hidden{
      @media (max-width: 990px){
        display: none;
      }
    }
  }
}

header.main{
  .wide{
    .row{
      .col-md-10{
        min-height: 88px;
        display: flex;
        align-items: center;
        @media (max-width: 990px){
          flex: 0 0 70%;
          max-width: 70%;
        }
        @media (max-width: 768px){
          min-height: 70px;
        }
        @media (max-width: 520px){
          display: block;
          padding-top: 10px;
          flex: 0 0 60%;
          max-width: 60%;
        }
      }
      .col-md-2{
        @media (max-width: 990px){
          flex: 0 0 30%;
          max-width: 30%;
        }
        @media (max-width: 520px){
          flex: 0 0 40%;
          max-width: 40%;
        }
      }
      a.logo{
        display: block;
        min-width: 146px;
        margin-right: 24px;
        @media (max-width: 1220px){
          width: 33%;
        }
        @media (max-width: 520px){
          margin-bottom: 5px;
        }
        img{
          width: 100%;
          height: auto;
        }
      }
      .contact_phone{
        margin-top: 13px;
        margin-right: 30px;
        @media (max-width: 520px){
          margin-right: 0;
        }
        a.phone{
          color: #000000;
          font-size: 22px;
          font-weight: 700;
          display: block;
          text-decoration: none;
          line-height: 22px;
          @media (max-width: 768px){
            font-size: 16px;
            line-height: 16px;
          }
        }
        p{
          display: inline-block;
          color: #000000;
          font-size: 12px;
          line-height: 12px;
          margin-bottom: 0;
          @media (max-width: 768px){
            display: none;
          }
        }
        a.call_me{
          font-size: 12px;
          display: inline-block;
          float: right;
          margin-top: 1px;
          color: #0a84b6;
          text-decoration: underline;
          span{
            font-size: 13px;
            &:nth-child(2){
              margin-left: -3px;
            }
          }
          @media (max-width: 1220px){
            font-size: 12px;
            margin-top: 2px;
          }
          @media (max-width: 768px){
            display: none;
          }
        }
      }
      .search_form{
        margin-top: 11px;
        @media (max-width: 990px){
          display: none;
        }
        form{
          width: 260px;
          input{
            width: 76%;
            display: inline-block;
            outline: none;
            border: 1px solid rgba(0,0,0, .1);
            max-width: 194px;
            height: 32px;
            font-size: 14px;
            //line-height: 14px;
            padding-left: 33px;
            background: url(../img/pics/search_icon.png) 7px 50% no-repeat;
            background-size: 15px 21px;
            color: #444;
            &::placeholder{
              opacity: 0.5;
              color: #000000;
            }
          }
          button{
            display: inline-block;
            width: 66px;
            height: 32px;
            background-color: #ffde00;
            color: #000000;
            text-align: center;
            border: none;
            outline: none;
            margin-left: -5px;
            position: relative;
            top: -1px;
          }
        }
      }
      .cart_div{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 13px;
        position: relative;
        @media (max-width: 520px){
          align-items: flex-end;
          padding-bottom: 14px;
        }
        .amount{
          display: block;
          color: #000000;
          font-weight: 400;
          margin-bottom: 0;
          margin-top: 10px;
          font-size: 22px;
          @media (max-width: 768px){
            font-size: 16px;
            line-height: 16px;
          }
        }
        .cart{
          margin-left: 8px;
          position: relative;
          @media (max-width: 768px){
            width: 34px;
            height: 32px;
            img{
              width: 100%;
              height: 100%;
            }
          }
          span{
            position: absolute;
            top: -4px;
            right: -8px;
            padding: 2px 4.8px;
            //width: 21px;
            //height: 21px;
            border: 2px solid #ffffff;
            background-color: #ff2828;
            color: #ffffff;
            font-size: 13px;
            line-height: 13px;
            border-radius: 50%;
            text-align: center;
            @media (max-width: 520px){
              display: none;
            }
          }
        }
      }
    }
  }
}