.item_slider{
  outline: none;
  .img-container{
    height: 95px;
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    align-items: flex-end;
    padding: 0 10px;
    img{
      max-height: 100%;
      max-width: 100%;
    }
  }
  .name{
    text-decoration: underline #0587ba;
    margin-bottom: 10px;
    max-width: 165px;
    font-size: 14px;
    padding: 0 10px;
    @media (max-width: 470px){
      font-size: 10px;
      line-height: 14px;
    }
    @media (max-width: 360px){
      max-width: none;
    }
    span{
      letter-spacing: 1.2px;
      font-size: 12px;
    }
  }
  .price{
    color: #000000;
    font-weight: 700;
    line-height: 14px;
    padding: 0 10px;
    margin-bottom: 10px;
    &::after{
      content: "₽";
      display: inline-block;
      margin-left: 3px;
    }
    @media (max-width: 470px){
      font-size: 12px;
      line-height: 12px;
    }
  }
  .button_container {
    margin-bottom: 20px;
    &.amount_mode {
      button.to_cart {
        display: none;
      }
      .amount {
        display: block;
      }
    }
    button.to_cart {
      width: 103px;
      padding-top: 0;
      padding-bottom: 0;
      border: none;
      border-radius: 0;
      height: 28px;
      color: #000000;
      font-weight: 700;
      outline: none;
      text-align: left;
      padding-left: 10px;
      line-height: 12px;
      background-color: #ffde00;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        right: -10px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 14px 0 14px 10px;
        border-color: transparent transparent transparent #ffde00;
      }
      &::after {
        content: "";
        background: url(../img/pics/cart_mini.png) no-repeat;
        width: 17px;
        height: 16px;
        display: inline-block;
        margin-left: 4px;
        vertical-align: sub;
      }
      &:focus {
        border: none;
        outline: none;
        box-shadow: none;
      }
    }
    .amount {
      display: none;
      max-width: 115px;
      .item-quantity {
        width: 49px;
        height: 28px;
        border: 1px solid #c1c1c1;
        background-color: #ffffff;
        text-align: center;
        display: inline-block;
      }
      .increase, .decrease {
        width: 19px;
        height: 28px;
        display: inline-block;
        background-color: #ffde00;
        vertical-align: top;
        border: none;
        outline: none;
        position: relative;
        color: #000000;
        font-weight: 700;
      }
      .increase::after {
        content: "";
        position: absolute;
        top: 0;
        right: -9px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 14px 0 14px 10px;
        border-color: transparent transparent transparent #ffde00;
      }
      .decrease {
        margin-left: 10px;
      }
      .decrease::before {
        content: "";
        position: absolute;
        top: 0;
        left: -10px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 14px 10px 14px 0;
        border-color: transparent #ffde00 transparent transparent;
      }
    }
  }
}