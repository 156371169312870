section.cabinet{
  margin-bottom: 44px;
  .wide{
    .row{
      position: relative;
      justify-content: space-between;
      .col-md-8{
        @media (max-width: 990px){
          max-width: 100%;
          flex: 0 0 100%;
          margin-top: 200px;
        }
        @media (max-width: 500px){
          margin-top: 305px;
        }
      }
      .order-item{
        max-width: 505px;
        width: 100%;
        margin: 20px 0 29px;
        padding: 20px 0 80px;
        @media (max-width: 990px){
          max-width: 100%;
        }
        h1{
          margin-bottom: 4px;
        }
        h3{
          margin-bottom: 37px;
        }
        .order-list{
          overflow: hidden;
          width: 100%;
          .item{
            max-height: none;
            opacity: 1;
            transition: all .5s ease;
            overflow: hidden;
            margin-bottom: 13px;
            &.hide{
              max-height: 0;
              transition: all .5s ease;
              margin-bottom: 0;
              opacity: 0;
            }
            p{
              display: inline-block;
              margin-bottom: 0;
            }
            p.name{
              width: 74%;
              color: #000000;
              letter-spacing: .4px;
              font-size: 22px;
              font-weight: 700;
              margin-right: 4px;
              margin-bottom: 4px;
              line-height: 22px;
              @media (max-width: 768px){
                font-size: 18px;
              }
              @media (max-width: 470px){
                font-size: 13px;
                line-height: 14px;
              }
              @media (max-width: 410px){
                width: 67%;
              }
              span{
                display: block;
                font-weight: 400;
                font-size: 14px;
                margin-top: 4px;
                border-bottom: 1px solid rgba(0,0,0,.2);
                @media (max-width: 470px){
                  font-size: 11px;
                }
              }
            }
            p.price{
              color: #000000;
              font-size: 22px;
              line-height: 22px;
              width: 15%;
              @media (max-width: 990px){
                font-size: 20px;
                line-height: 28px;
              }
              @media (max-width: 470px){
                font-size: 16px;
              }
            }
            p.amount{
              white-space: nowrap;
              max-width: 10%;
              font-size: 22px;
              @media (max-width: 990px){
                font-size: 20px;
                line-height: 28px;
              }
              @media (max-width: 470px){
                font-size: 16px;
                max-width: 15%;
              }
            }
          }
        }
        a.more{
          color: #0a84b6;
          font-size: 22px;
          font-weight: 700;
          line-height: 22px;
          display: inline-block;
          margin-bottom: 23px;
          text-decoration: underline;
          @media (max-width: 990px){
            font-size: 20px;
            line-height: 28px;
          }
          @media (max-width: 470px){
            font-size: 18px;
            line-height: 25px;
          }
        }
        .all-amount{
          display: block;
          margin-top: 26px;
          border-top: 2px solid #ccc;
          p{
            color: #000000;
            font-size: 22px;
            font-weight: 700;
            letter-spacing: .8px;
            line-height: 26px;
            min-width: 199px;
            float: right;
            white-space: nowrap;
            margin-top: 7px;
            @media (max-width: 990px){
              font-size: 20px;
              line-height: 28px;
            }
            @media (max-width: 470px){
              font-size: 18px;
              line-height: 25px;
            }
          }
        }
      }
      a.more_orders{
        display: block;
        max-width: 293px;
        height: 48px;
        margin-top: -28px;
        background-color: #ffde00;
        color: #000000;
        font-size: 22px;
        font-weight: 700;
        line-height: 34px;
        text-decoration: none;
        text-align: center;
        outline: none;
        box-shadow: none;
        border-radius: 0;
        margin-bottom: 70px;
      }

      .col-md-4{
        max-width: 365px;
        @media (max-width: 990px){
          max-width: 100%;
          flex: 0 0 100%;
          position: static;
        }
        .user-data{
          margin-top: 50px;
          padding: 0 15px;
          margin-bottom: 84px;
          @media (max-width: 990px){
            position: absolute;
            top: 0;
            left: 0;
            padding: 0 15px;
            width: 100%;
            margin-top: 20px;
            margin-bottom: 0;
          }
          @media (max-width: 768px){
            padding: 0 10px;
          }
          &.b2c_data_user{
            margin-bottom: 118px;
            .user .name{
              margin-bottom: 19px;
            }
            .user{
              @media (max-width: 768px){
                padding-right: 0;
                max-width: 100%;
              }
            }
          }
          .user{
            @media (max-width: 990px){
              max-width: 60%;
              display: inline-block;
              padding-right: 40px;
            }
            @media (max-width: 500px){
              display: block;
              max-width: 100%;
              padding-right: 0;
            }
          }
          .name{
            line-height: 24px;
            margin-bottom: 5px;
            //margin-bottom: 19px;
            overflow: hidden;
          }
          p.data{
            margin-bottom: 14px;
            font-size: 14px;
            font-weight: 400;
            line-height: 14px;
          }
          .edit{
            color: #0a84b6;
            font-weight: 400;
            //line-height: 28px;
            text-decoration: underline #0a84b6;
            font-size: 14px;
            margin-top: 11px;
            margin-bottom: 18px;
            display: inline-block;
            line-height: 14px;
            &:first-of-type{
              margin-right: 21px;
            }
          }
          .exit{
            width: 173px;
            height: 48px;
            background-color: #413f43;
            color: #ffffff;
            font-size: 22px;
            font-weight: 700;
            line-height: 44px;
            margin: 19px 0;
            display: block;
            text-decoration: none;
            text-align: center;
          }
          .manager-data{
            margin-top: 40px;
            @media (max-width: 990px){
              display: inline-block;
              max-width: 40%;
            }
            @media (max-width: 500px){
              display: block;
              max-width: 100%;
            }
            p{
              margin-bottom: 2px;
            }
            a.phone-manager{
              color: #0f81b2;
              font-weight: 400;
              margin-top: 11px;
              letter-spacing: .5px;
              margin-bottom: 20px;
              font-size: 22px;
              line-height: 32px;
              text-decoration: underline;
              display: inline-block;
              @media (max-width: 500px){
                margin-top: 0;
              }
            }
          }
        }

        .sale{
          margin-bottom: 94px;
          margin-left: 4px;
          max-width: 100%;
        }
      }
      .col-md-12{
        margin-top: 44px;
        @media (max-width: 768px){
          margin-top: 0;
        }
      }
      .container_for_slider{
        position: relative;
        @media (max-width: 1240px){
          padding: 0 30px;
        }
        @media (max-width: 990px){
          padding: 0;
        }
        .slick-prev, .slick-next{
          position: absolute;
          width: 20px;
          height: 41px;
          top: 100px;
          background: transparent;
          border-radius: 0;
          outline: none;
          border: none;
          cursor: pointer;
        }
        .slick-prev{
          left: -30px;
          background-image: url(../img/pics/arrow_slick_prev.png);
          background-position: 100% 50%;
          background-repeat: no-repeat;
          transition: all .5s ease;
          @media (max-width: 1240px){
            left: 10px;
          }
          &:hover{
            background-position: 0% 50%;
          }
        }
        .slick-next{
          right: -30px;
          background-image: url(../img/pics/arrow_slick_next.png);
          background-position: 0% 50%;
          background-repeat: no-repeat;
          transition: all .5s ease;
          @media (max-width: 1240px){
            right: 10px;
          }
          &:hover{
            background-position: 100% 50%;
          }
        }
      }
    }
  }
  &.b2c_cab{
    .wide {
      .row {
        .col-md-8 {
          @media (max-width: 990px) {
            max-width: 100%;
            flex: 0 0 100%;
            margin-top: 250px;
          }
          @media (max-width: 500px){
            //margin-top: 305px;
          }
        }
      }
    }
  }
}