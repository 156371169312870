section.level3{
  padding-top: 43px;
  .wide{
    .row{
      h1{
        margin-bottom: 10px;
      }
      h2{
        margin-bottom: 50px;
      }
      &.slider_block{
        h1{
          margin-bottom: 45px;
        }
      }
      &.best_for_buyers{
        margin-bottom: 120px;
        @media (max-width: 768px){
          margin-bottom: 70px;
        }
        .col-md-3{
          @media (max-width: 990px){
            flex: 0 0 50%;
            max-width: 50%;
            margin-bottom: 40px;
          }
        }
        .img-container{
          display: block;
          height: 248px;
          margin-bottom: 15px;
          @media (max-width: 768px){
            height: 140px;
            width: 50%;
            img{
              max-height: 100%;
            }
          }
          @media (max-width: 480px){
            height: 120px;
          }
        }
        .item-content{
          @media (max-width: 990px){
            width: 100%;
            padding-left: 0;
            padding-right: 0;
            max-width: 100%;
            .name{
              a br{
                display: none;
              }
            }
          }
          .button_container{
            margin-right: 72px;
            @media (max-width: 1100px){
              margin-right: 30px;
            }
          }
        }
      }
      &.filter{
        margin-top: 50px;
        .type{
          width: 100%;
          &:last-of-type{
            margin-top: 4px;
          }
          p{
            display: inline-block;
            font-weight: 700;
            width: 126px;
            @media (max-width: 990px){
              width: auto;
              display: block;
              margin-top: 10px;
              margin-bottom: 4px;
            }
          }
          .filter-point{
            display: inline-block;
            margin-bottom: 13px;
            border: 1px solid #cccccc;
            border-radius: 5px;
            @media (max-width: 990px){
              border: none;
              margin-bottom: 5px;
            }
            input[type=checkbox]{
              display: none;
            }
            label{
              display: inline-block;
              cursor: pointer;
              color: #000000;
              padding: 7px 15.5px;
              margin-left: -4px;
              transition: all .5s ease;
              margin-bottom: 0;
              @media (max-width: 1100px){
                padding: 7px 8px;
              }
              @media (max-width: 990px){
                border-radius: 5px;
                border: 1px solid #ccc;
                margin-left: 0;
                margin-bottom: 5px;
              }
              &:hover{
                background-color: #dff5ff;
              }
              &:first-of-type{
                border-radius: 5px 0 0 5px;
                margin-left: 0;
                @media (max-width: 990px){
                  border-radius: 5px;
                }
              }
              &:last-of-type{
                border-radius: 0 5px 5px 0;
                @media (max-width: 990px){
                  border-radius: 5px;
                }
              }
            }
            input[type=checkbox]:checked + label{
              background-color: #413f43;
              color: #ffffff;
            }
          }
          a.show_all{
            display: inline-block;
            margin-bottom: 0;
            color: #008abe;
            line-height: 20px;
            text-decoration: underline;
            margin-left: 10px;
            @media (max-width: 990px){
              margin-left: 0;
              margin-bottom: 10px;
            }
          }

          .availability-checkbox{
            border: none;
            background: white;
            margin-right: 10px;
            outline: none;
          }
          .availability-checkbox + label{
            position: relative;
            &::before{
              content: "";
              width: 21px;
              height: 21px;
              border: 2px solid rgba(0,0,0,.2);
              background: white;
              position: absolute;
              top: -3px;
              left: -26px;
            }
          }
          .availability-checkbox:checked + label{
            &::after{
              content: "";
              background: url(../img/pics/ok_icon.png) no-repeat;
              width: 20px;
              height: 16px;
              position: absolute;
              top: -1px;
              left: -22px;
            }
          }

          .select-sort{
            margin-left: 47px;
            display: inline-block;
            @media (max-width: 768px){
              margin-left: 0;
              margin-top: 10px;
              display: block;
            }
            p{
              display: inline-block;
            }
            .select_wrapper{
              width: 239px;
              display: inline-block;
              height: 37px;
              border: 1px solid #cccccc;
              position: relative;
              &::after{
                content: "";
                width: 13px;
                height: 10px;
                background: url(../img/pics/arrow_down.png) no-repeat;
                background-size: contain;
                position: absolute;
                top: calc(50% - 4px);
                right: 11px;
                z-index: -1;
              }
            }
            select{
              width: 100%;
              height: 100%;
              padding-left: 10px;
              outline: none;
              border: none;
              -webkit-appearance: none;
              -moz-appearance: none;
              appearance: none;
              background: transparent;
            }
          }

          .visible-type{
            float: right;
            @media (max-width: 480px){
              margin-top: 15px;
            }
            input[type=radio]{
              display: none;
            }
            label{
              width: 33px;
              height: 33px;
              cursor: pointer;
              margin-bottom: 0;
              margin-left: 16px;
              &.button-list{
                background: url(../img/pics/list-type-button.png) no-repeat;
                background-size: contain;
                opacity: 0.2;
              }
              &.button-table{
                background: url(../img/pics/table-type-button.png) no-repeat;
                background-size: contain;
                opacity: 0.2;
              }
            }
            input[type=radio]:checked + label{
              opacity: 1;
              cursor: auto;
            }
          }

        }
        hr{
          background: rgba(0,0,0,.2);
          margin-bottom: 33px;
          @media (max-width: 768px){
            margin-top: 50px;
          }
          @media (max-width: 480px){
            margin-top: 70px;
          }
        }
      }

      &.filter-results{
        margin-bottom: 0;
        margin-top: 10px;
        .col-md-2{
          margin-bottom: 71px;
          @media (max-width: 990px){
            flex: 0 0 33.3%;
            max-width: 33.3%;
          }
          @media (max-width: 480px){
            flex: 0 0 50%;
            max-width: 50%;
            margin-bottom: 40px;
          }
          .item-container{
            .img-container{
              display: block;
              height: 100px;
              width: 100%;
              margin-bottom: 7px;
            }
            .item-content{
              display: block;
              width: 100%;
              max-width: 100%;
              @media (max-width: 990px){
                padding-right: 0;
                padding-left: 0;
              }
            }
          }
        }
        .col-md-4{
          align-self: stretch;
          margin-bottom: 71px;
          padding-right: 39px;
          padding-left: 13px;
          @media (max-width: 990px){
            flex: 0 0 66.6%;
            max-width: 66.6%;
          }
          @media (max-width: 480px){
            flex: 0 0 50%;
            max-width: 50%;
            margin-bottom: 40px;
            padding-left: 10px;
            padding-right: 10px;
          }
          @media (max-width: 400px){
            flex: 0 0 100%;
            max-width: 100%;
          }
          .send_me_a_photo{
            border: 3px solid #8bcbe8;
            padding-top: 52px;
            padding-left: 40px;
            width: 100%;
            height: 100%;
            @media (max-width: 1030px){
              padding-left: 11px;
            }
            @media (max-width: 480px){
              padding-top: 35px;
              padding-bottom: 30px;
            }
          }
          img{
            display: inline-block;
            margin-right: 13px;
            @media (max-width: 480px){
             display: none;
            }
          }
          p{
            display: inline-block;
            vertical-align: top;
            max-width: 63%;
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
            margin-top: 6px;
            color: #000000;
            @media (max-width: 480px){
              max-width: 100%;
            }
            a{
              text-decoration: underline;
              color: #0a84b6;
            }
          }
        }
        .col-md-12{
          text-align: center;
          margin-bottom: 95px;
          margin-top: -30px;
          a.page_num{
            display: inline-block;
            margin: 0 14px;
            font-weight: 700;
            font-size: 20px;
            text-decoration: underline;
            &.prev, &.next{
              font-size: 20px;
            }
          }
        }
      }
      &.about_catalog_level{
        margin-bottom: 84px;
        margin-top: 114px;
        .col-md-9{
          @media (max-width: 991px){
            max-width: 100%;
            flex: 0 0 100%;
          }
        }
        h1{
          margin-bottom: 28px;
        }
        h3{
          margin-bottom: 34px;
          line-height: 34px;
        }
        .col-md-3{
          padding-left: 59px;
          margin-bottom: 18px;
          align-self: flex-end;
          @media (max-width: 991px){
            flex: 0 0 100%;
            max-width: 100%;
            padding-left: 15px;
          }
          @media (max-width: 768px){
            padding-left: 10px;
          }
          strong{
            display: block;
            margin-bottom: 3px;
          }
          p{
            line-height: 20px;
          }
        }
        .slider{
          margin-top: 48px;
          h1{
            margin-bottom: 49px;
          }
        }
        .enter{
          margin-bottom: 0;
          margin-top: 50px;
        }
      }
    }
  }
}