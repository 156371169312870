section.article{
  margin-bottom: 64px;
  padding-top: 20px;
  .wide{
    .row{
      justify-content: space-between;
      .col-md-10{
        max-width: 976px;
        @media (max-width: 990px){
          flex: 0 0 100%;
          max-width: 100%;
        }
      }
      .img_container{
        max-width: 100%;
        width: 100%;
        height: auto;
        max-height: 536px;
        img{
          max-width: 100%;
          height: auto;
        }
      }
      article{
        border-bottom: 2px solid #eee;
        padding-top: 56px;
        margin-bottom: 22px;
        padding-bottom: 35px;
        h1{
          margin-bottom: 0;
        }
        p.date{
          color: #000000;
          margin-top: -1px;
        }
        .text{
          margin-top: 42px;
          width: 100%;
          p{
            color: #000000;
            font-size: 22px;
            margin-bottom: 34px;
            line-height: 34px;
            @media (max-width: 990px){
              font-size: 20px;
              line-height: 28px;
            }
            @media (max-width: 768px){
              font-size: 18px;
              line-height: 26px;
            }
            @media (max-width: 470px){
              font-size: 16px;
              line-height: 23px;
            }
          }
        }
      }
      a.prev, a.next{
        display: inline-block;
        color: #0587ba;
        font-size: 24px;
        line-height: 34px;
        text-decoration: underline;
        position: relative;
        max-width: 360px;
        @media (max-width: 990px){
          font-size: 20px;
          line-height: 28px;
          max-width: 47%;
        }
        @media (max-width: 600px){
          font-size: 18px;
          line-height: 25px;
          max-width: calc(47% - 20px);
        }
        @media (max-width: 400px){
          display: block;
          max-width: calc(100% - 20px);
        }
      }
      a.prev{
        @media (max-width: 600px){
          margin-left: 20px;
        }
        &::before{
          content: "";
          background: url(../img/pics/arrow_prev.png) no-repeat;
          width: 20px;
          height: 20px;
          position: absolute;
          top: 10px;
          left: -25px;
          @media (max-width: 990px){
            top: 7px;
          }
        }
      }
      a.next {
        float: right;
        text-align: right;
        @media (max-width: 600px){
          margin-right: 20px;
        }
        @media (max-width: 400px){
          float: none;
          margin-top: 20px;
        }
        &::after {
          content: "";
          background: url(../img/pics/arrow_prev.png) no-repeat;
          width: 20px;
          height: 20px;
          position: absolute;
          top: 7px;
          right: -25px;
          transform: rotate(180deg);
          @media (max-width: 990px){
            top: 4px;
          }
        }
      }
      .col-md-2{
        @media (max-width: 990px){
          flex: 0 0 100%;
          max-width: 100%;
          margin-top: 50px;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
        }
        .item{
          width: 100%;
          margin-bottom: 23px;
          @media (max-width: 990px){
            width: 47%;
          }
        }
        .small_one{
          p.date{
            font-size: 9px;
            line-height: 12px;
            margin-bottom: 2px;
          }
          .img_container{
            height: 100px;
            max-width: 100%;
            overflow: hidden;
            margin-bottom: 10px;
            img{
              width: auto;
              min-width: 100%;
              min-height: 100%;
            }
            @media (max-width: 990px){
              height: 180px;
            }
            @media (max-width: 768px){
              height: 137px;
            }
            @media (max-width: 600px){
              height: 27vw;
            }
          }
          .text_container{
            min-height: 127px;
            max-height: 127px;
            overflow: hidden;
            @media (max-width: 990px){
              max-height: 113px;
              min-height: 113px;
            }
          }
          p, a{
            font-size: 14px;
            margin-bottom: 0;
            line-height: 17px;
            @media (max-width: 600px){
              font-size: 13px;
              line-height: 15px;
            }
          }
          a{
            margin-bottom: 5px;
            font-weight: 600;
            display: block;
            text-decoration: underline;
          }
        }
      }
    }
  }
}