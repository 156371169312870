section.khalculator_page{
  padding-top: 47px;
  padding-bottom: 20px;
  margin-bottom: 96px;
  margin-top: 0;
  .wide{
    .row{
      justify-content: space-between;
      .col-mad-12{
        margin-bottom: 20px;
      }
      h1{
        margin-bottom: 33px;
      }
      h3{
        max-width: 800px;
        margin-bottom: 22px;
      }
      .col-md-8{
        max-width: 570px;
        @media (max-width: 768px){
          max-width: 100%;
        }
        form{
          width: 93%;
          max-width: 540px;
          min-width: 540px;
        //width: 422px;
        //height: 874px;
          background-color: rgba(173,171,175,.2);
          border-radius: 40px;
          padding: 39px 19px 45px;
          @media (max-width: 990px){
            h3 br{
              display: none;
            }
            width: 100%;
          }
          @media (max-width: 670px){
            min-width: unset;
            padding: 30px 15px 40px;
          }
          @media (max-width: 365px){
            padding: 20px 10px 30px;
          }
          h3{
            margin-bottom: 10px;
          }
          .input_container{
            margin-top: 13px;
            margin-bottom: 9px;
            p{
              display: inline-block;
              color: #000000;
              font-size: 16px;
              line-height: 16px;
              @media (max-width: 470px){
                font-size: 13px;
                line-height: 15px;
              }
            }
            input{
              outline: none;
              width: 54px;
              height: 32px;
              box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.5);
              border: 1px solid #c1c1c1;
              background-color: #ffffff;
              display: inline-block;
              text-align: center;
              font-size: 25px;
              line-height: 25px;
              margin: 0 5px;
              padding: 0;
              border-radius: 0;
              -moz-appearance:textfield;
              @media (max-width: 470px){
                width: 46px;
                height: 27px;
                font-size: 21px;
              }
            }
            input[type=number]::-webkit-inner-spin-button,
            input[type=number]::-webkit-outer-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
          }
          hr{
            height: 1.5px;
            border: none;
            background: rgba(0,0,0,1);
            margin-top: 0;
            max-width: 75.5%;
            margin-bottom: 13px;
            float: left;
          }
          .item{
            display: block;
            margin-bottom: 6px;
            p{
              display: inline-block;
              margin-bottom: 0;
            }
            p.name{
              width: 57.5%;
              text-shadow: 0 1px 0 #ffffff;
              color: #000000;
              font-size: 16px;
              font-weight: 700;
              letter-spacing: 0.4px;
              line-height: 18px;
              @media (max-width: 520px){
                font-size: 13px;
                line-height: 14px;
                width: calc(78.5% - 110px);
              }
              @media (max-width: 450px){
                width: calc(78.5% - 36px);
              }
              span{
                display: block;
                margin-top: 2px;
                font-weight: 400;
                font-size: 11px;
                line-height: 13px;
                border-bottom: 1px solid rgba(0,0,0,.2);
                @media (max-width: 470px){
                  font-size: 9px;
                  line-height: 11px;
                }
              }
            }
            p.price{
              color: #000000;
              font-size: 17px;
              text-align: left;
              line-height: 17px;
              margin-left: 1.5%;
              width: 8%;
              vertical-align: middle;
              position: relative;
              top: 1px;
              @media (max-width: 520px){
                font-size: 13px;
              }
            }
            p.amount{
              width: 7%;
              //max-width: 10%;
              font-size: 17px;
              margin-right: 1%;
              vertical-align: middle;
              position: relative;
              top: 1px;
              @media (max-width: 520px){
                font-size: 13px;
              }
            }
            .button_container{
              width: 21%;
              display: inline-block;
              position: relative;
              top: 3px;
              min-width: 102px;
              @media (max-width: 450px){
                width: 36px;
                min-width: unset;
              }
            }
            a.to_cart{
              width: 100%;
              padding-top: 0;
              padding-bottom: 0;
              border: none;
              border-radius: 0;
              height: 28px;
              color: #ffffff;
              font-weight: 700;
              outline: none;
              text-align: left;
              padding-left: 10px;
              display: block;
              line-height: 28px;
              background: #e0e0e1;
              position: relative;
              &::before{
                content: "";
                position: absolute;
                top: 0;
                right: -10px;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 14px 0 14px 10px;
                border-color: transparent transparent transparent #e0e0e1;
              }
              &:focus{
                border: none;
                outline: none;
                box-shadow: none;
              }
              @media (max-width: 450px){
                color: transparent;
                overflow: hidden;
              }
              &.active{
                color: #000000;
                background-color: #ffde00;
                &::after{
                  content: "";
                  background: url(../img/pics/cart_mini.png) no-repeat;
                  width: 17px;
                  height: 16px;
                  display: inline-block;
                  margin-left: 4px;
                  vertical-align: sub;
                  @media (max-width: 450px){
                    position: absolute;
                    top: 6px;
                    left: 5px;
                  }
                }
                &::before{
                  content: "";
                  position: absolute;
                  top: 0;
                  right: -10px;
                  width: 0;
                  height: 0;
                  border-style: solid;
                  border-width: 14px 0 14px 10px;
                  border-color: transparent transparent transparent #ffde00;
                }
                @media (max-width: 450px){
                  color: transparent;
                }
              }
            }
          }
          .amount_price{
            width: 18.5%;
            margin-left: 56%;
            margin-top: 16px;
            white-space: nowrap;
            border-top: 1px solid black;
            font-weight: 700;
            font-size: 17px;
            padding-right: 50px;
            text-align: right;
            padding-top: 3px;
            @media (max-width: 520px){
              font-size: 13px;
              width: 21%;
              margin-left: 51%;
            }
            @media (max-width: 450px){
              padding-right: 28px;
              width: 26%;
              margin-left: 59%;
            }
          }
          button.buy{
            width: 100%;
            background: #e0e0e1;
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            margin-top: 20px;
            height: 55px;
            border-radius: 0;
            cursor: auto;
            box-shadow: none;
            color: #ffffff;
            @media (max-width: 470px){
              font-size: 14px;
              height: 46px;
            }
            &.active{
              background-color: #ffde00;
              box-shadow: 0 11px 40px #ffffff;
              color: #000000;
              cursor: pointer;
            }
            &::after{
              display: none;
            }
          }
        }
      }
      .col-md-4{
        padding-left: 46px;
        @media (max-width: 990px){
          padding-left: 15px;
          max-width: 100%;
          margin-left: 0;
          flex: 0 0 100%;
        }
        @media (max-width: 768px){
          padding-left: 10px;
        }
      }
    }
  }
}