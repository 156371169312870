section.promo_page{
  padding-bottom: 108px;
  padding-top: 21px;
  @media (max-width: 990px){
    padding-bottom: 30px;
  }
  .wide{
    .row{
      justify-content: space-between;
      .col-md-8{
        flex: 0 0 67.666667%;
        max-width: 67.666667%;
        @media (max-width: 990px){
          flex: 0 0 100%;
          max-width: 100%;
        }
      }
      .col-md-4{
        flex: 0 0 31%;
        max-width: 31%;
        @media (max-width: 990px){
          flex: 0 0 100%;
          max-width: 100%;
          margin-top: 90px;
        }
      }
      .promo_data{
        border-bottom: 2px solid rgba(0,0,0,.2);
        .img_container{
          margin-bottom: 61px;
          max-width: 100%;
          img{
            max-width: 100%;
            width: 100%;
          }
        }
        .head.date{
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 0.35px;
          font-weight: 400;
        }
        .text{
          margin-top: 28px;
          padding-bottom: 35px;
          @media (max-width: 990px){
            padding-bottom: 0;
          }
          h3{
            margin-bottom: 34px;
          }
        }
      }
      a.prev, a.next{
        margin-top: 22px;
        display: inline-block;
        color: #0587ba;
        font-size: 24px;
        line-height: 34px;
        text-decoration: underline;
        position: relative;
        max-width: 360px;
        @media (max-width: 990px){
          font-size: 20px;
          line-height: 28px;
          max-width: 47%;
        }
        @media (max-width: 600px){
          font-size: 18px;
          line-height: 25px;
          max-width: calc(47% - 20px);
        }
        @media (max-width: 400px){
          display: block;
          max-width: calc(100% - 20px);
        }
      }
      a.prev{
        margin-left: 22px;
        &::before{
          content: "";
          background: url(../img/pics/arrow_prev.png) no-repeat;
          width: 20px;
          height: 20px;
          position: absolute;
          top: 10px;
          left: -22px;
          @media (max-width: 990px){
            top: 7px;
          }
        }
      }
      a.next {
        float: right;
        text-align: right;
        margin-right: 22px;
        @media (max-width: 400px){
          float: none;
        }
        &::after {
          content: "";
          background: url(../img/pics/arrow_prev.png) no-repeat;
          width: 20px;
          height: 20px;
          position: absolute;
          top: 7px;
          right: -22px;
          transform: rotate(180deg);
          @media (max-width: 990px){
            top: 4px;
          }
        }
      }
      .gray_item{
        margin-bottom: 88px;
      }
    }
  }
}