section.guru{
  padding-bottom: 99px;
  padding-top: 35px;
  @media (max-width: 990px){
    padding-bottom: 50px;
  }
  .wide{
    .row{
      .col-md-5{
        max-width: 34%;
        flex: 0 0 34%;
        @media (max-width: 550px){
          max-width: 50%;
          flex: 0 0 50%;
        }
        @media (max-width: 400px){
          max-width: 100%;
          flex: 0 0 100%;
        }
      }
      .col-md-7{
        max-width: 60.5%;
        padding-left: 19px;
        flex: 0 0 60.5%;
        @media (max-width: 768px){
          max-width: 100%;
          flex: 0 0 100%;
          padding-left: 15px;
        }
      }
      .img_container{
        overflow: hidden;
        max-width: 100%;
        img{
          max-width: 100%;
          max-height: 100%;
        }
      }
      h1.no_underline{
        line-height: 50px;
        margin-bottom: 22px;
      }
      h3{
        line-height: 35px;
        span{
          font-weight: 400;
          max-width: 515px;
          display: block;
        }
      }
      form{
        display: block;
        width: 100%;
        margin-top: 20px;
        position: relative;
        margin-bottom: 45px;
        max-width: 100%;
        @media (max-width: 768px){
          height: 35px;
        }
        @media (max-width: 420px){
          margin-bottom: 25px;
        }
        input{
          width: calc(100% - 186px);
          display: inline-block;
          outline: none;
          border: 2px solid rgba(0,0,0, .2);
          height: 60px;
          font-size: 28px;
          padding: 0 0 0 15px;
          box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.3);
          color: #444;
          @media (max-width: 768px){
            font-size: 17px;
            line-height: 17px;
            border-width: 1px;
            height: 35px;
          }
          @media (max-width: 420px){
            width: calc(100% - 112px);
            font-size: 14px;
          }
          &::placeholder{
            opacity: 0.5;
            color: #000000;
          }
        }
        button{
          display: inline-block;
          width: 188px;
          height: 61px;
          background-color: #ffde00;
          color: #000000;
          font-size: 28px;
          font-weight: 700;
          text-align: center;
          border: none;
          outline: none;
          margin-left: -7px;
          position: relative;
          top: 1px;
          @media (max-width: 768px){
            height: 35px;
            font-size: 18px;
            line-height: 17px;
            position: absolute;
            top: 0;
            right: 0;
          }
          @media (max-width: 420px){
            width: 114px;
          }
        }
      }
      a.phone_num{
        color: #453e42;
        font-size: 60px;
        display: inline-block;
        text-decoration: none;
        @media (max-width: 990px){
          font-size: 45px;
        }
        @media (max-width: 420px){
          font-size: 33px;
        }
      }
      p{
        color: #000000;
        display: inline-block;
        max-width: 75px;
        line-height: 17px;
        margin-bottom: 0;
        margin-lefT: 16px;
        position: relative;
        top: -5px;
        @media (max-width: 990px){
          display: block;
          max-width: 100%;
          margin-left: 0;
        }
      }
    }
  }
}

section.khalculator{
  margin-top: -10px;
  margin-bottom: 154px;
  .wide{
    .row{
      .col-md-6{
        max-width: 48%;
        flex: 0 0 48%;
        @media (max-width: 768px){
          max-width: 100%;
          flex: 0 0 100%;
        }
      }
      form{
        background-color: rgba(173,171,175,.2);
        border-radius: 40px;
        max-width: 520px;
        padding: 44px 20px 60px;
        select{
          box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.3);
          border: 1px solid #c1c1c1;
          padding: 0 30px 0 10px;
          background-color: #ffffff;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          background-image: url(../img/pics/arrow_down.png);
          background-repeat: no-repeat;
          background-size: 12px 7px;
          background-position: calc(100% - 10px) 50%;
          outline: none;
        }
        select.type{
          width: 100%;
          height: 40px;
          color: #000000;
          font-size: 22px;
          font-weight: 700;
          line-height: 30px;
          margin-bottom: 35px;
          @media (max-width: 420px){
            font-size: 18px;
          }
        }
        .form-group{
          margin-bottom: 9px;
          p, input{
            display: inline-block;
          }
          .help-block{
            display: block;
          }
          p{
            color: #000000;
            font-size: 16px;
            margin-bottom: 0;
            @media (max-width: 420px){
              font-size: 14px;
            }
            &:first-of-type{
              width: 168px;
              @media (max-width: 420px){
                width: 158px;
              }
            }
          }
          input{
            width: 47px;
            height: 29px;
            box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.3);
            border: 1px solid #c1c1c1;
            background-color: #ffffff;
            font-size: 25px;
            text-align: center;
            margin: 0 2px;
            padding: 0;
            border-radius: 0;
            outline: none;
            color: #000000;
            &::placeholder{
              color: #000000;
            }
          }
          &.product_select{
            margin-bottom: 21px;
            p{
              width: 75px;
              @media (max-width: 420px){
                width: 75px;
              }
            }
            select{
              width: calc(100% - 79px);
              color: #000000;
              font-size: 16px;
              height: 29px;
            }
          }
        }
        hr{
          display: block;
          width: 100%;
          height: 2px;
          border: none;
          background: #000;
          opacity: .5;
          margin-top: 55px;
          margin-bottom: 10px;
        }
        p.result{
          color: #010001;
          font-size: 72px;
          font-weight: 800;
          line-height: 66px;
          display: inline-block;
          @media (max-width: 420px){
            font-size: 48px;
          }
        }
        p.details{
          color: #000000;
          display: inline-block;
          margin-left: 10px;
          line-height: 18px;
          span{
            font-weight: 800;
          }
        }
        button{
          display: block;
          height: 55px;
          margin-top: 8px;
          width: 100%;
          outline: none;
          box-shadow: 0 11px 40px #ffffff;
          background-color: #ffde00;
          border: none;
          text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
          color: #000000;
          font-size: 16px;
          font-weight: 700;
          &::afteR{
            content: "";
            background: url(../img/pics/cart_icon_small.png) no-repeat;
            width: 16px;
            height: 15px;
            display: inline-block;
            //background-size: contain;
            margin-left: 2px;
            position: relative;
            left: 10px;
          }
        }
      }
      .sale{
        margin-top: 30px;
        margin-left: 8px;
        max-width: 370px;
        @media (max-width: 768px){
          max-width: 100%;
          margin-top: 30px;
          margin-left: 0;
        }
        p.head{
          margin-top: 0;
          margin-right: -4px;
          display: inline-block;
          padding: 0 10px;
          margin-bottom: 0;
          color: #ffffff;
          background-color: #ff1800;
          font-size: 54px;
          font-weight: 700;
          letter-spacing: 1.35px;
          line-height: 58px;
          @media (max-width: 420px){
            font-size: 50px;
          }
          span{
            font-weight: 400;
            font-size: 22px;
            line-height: 46px;
            display: block;
          }
        }
        p{
          padding: 0 10px;
          margin-bottom: 0;
          margin-top: 10px;
          line-height: 20px;
          &:nth-child(7){
            margin-top: 19px;
          }
          strong{
            display: block;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}