section.articles, section.blog{
  margin-bottom: 54px;
  .wide{
    .row{
      h1{
        margin-bottom: 34px;
      }
      .grid{
        @media (max-width: 990px){
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
        }
      }
      .item, .grid_sizer{
        width: 15.18%;
        @media (max-width: 1270px){
          width: calc(15.66% - 8px);
        }
        @media (max-width: 990px){
          width: calc(33.33% - 13.33px);
        }
        @media (max-width: 600px){
          width: calc(50% - 10px);
        }

        &.item--width3{
          width: calc(50% - 13px);
          @media (max-width: 1270px){
            width: calc(50% - 20px);
          }
          @media (max-width: 990px){
            width: 100%;
          }
        }
      }
      .item{
        margin-bottom: 12px;
      }
      .grid_sizer{
        @media (max-width: 990px){
          display: none;
        }
      }
      .gutter{
        width: 20px;
        @media (max-width: 990px){
          display: none;
        }
      }
      .img_container{
        max-width: 100%;
        overflow: hidden;
        margin-bottom: 8px;
        height: 347px;
        img{
          width: auto;
          min-width: 100%;
          min-height: 100%;
        }
      }
      p{
        color: #000;
        margin-bottom: 0;
      }
      a{
        margin-bottom: 4px;
        font-weight: 600;
        display: block;
        text-decoration: underline;
      }
      .big_one{
        float: left;
        .img_container{
          max-width: 100%;
          overflow: hidden;
          height: 347px;
          @media (max-width: 990px){
            height: 466px;
          }
          @media (max-width: 768px){
            height: 360px;
          }
          @media (max-width: 600px){
            height: 53vw;
          }
          img{
            width: 100%;
            min-width: 100%;
            min-height: 100%;
          }
        }
        .text_container{
          max-height: 204px;
          overflow: hidden;
          a.name{
            margin-top: -3px;
          }
        }
        a{
          letter-spacing: .5px;
          font-size: 20px;
          line-height: 28px;
          @media (max-width: 990px){
            font-size: 18px;
            line-height: 25px;
            letter-spacing: normal;
          }
          @media (max-width: 600px){
            font-size: 13px;
            line-height: 15px;
          }
        }
        p{
          letter-spacing: .5px;
          font-size: 20px;
          line-height: 24px;
          @media (max-width: 990px){
            font-size: 18px;
            line-height: 22px;
            letter-spacing: normal;
          }
          @media (max-width: 600px){
            font-size: 13px;
            line-height: 15px;
          }
        }
        &.no_img{
          height: auto;
          max-height: 252px;
          .text_container{
            max-height: 259px;
            p{
              margin-bottom: 10px;
            }
          }
        }
      }
      .small_one{
        @media (max-width: 990px){
          margin-bottom: 10px;
        }
        @media (max-width: 500px){
          margin-bottom: 20px;
        }
        .img_container{
          height: 100px;
          margin-bottom: 8px;
          @media (max-width: 990px){
            height: 149px;
          }
          @media (max-width: 768px){
            height: 100px;
          }
          @media (max-width: 600px){
            height: 27vw;
          }
          img{
            max-height: 100%;
            max-width: 100%;
          }
        }
        .text_container{
          min-height: 127px;
          max-height: 127px;
          overflow: hidden;
          @media (max-width: 990px){
            max-height: 110px;
            min-height: 110px;
          }
        }
        a{
          margin-bottom: 6px;
          letter-spacing: 1px;
        }
        p, a{
          font-size: 13px;
          line-height: 17px;
          @media (max-width: 600px){
            line-height: 15px;
          }
        }
        p{
          letter-spacing: 0.8px;
        }
      }
    }
  }
}