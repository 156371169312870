section.level2{
  padding-top: 51px;
  .wide{
    .row{
      .item-container{
        margin-bottom: 39px;
        @media (max-width: 991px){
          flex: 0 0 33%;
          max-width: 33%;
        }
        @media (max-width: 768px){
          flex: 0 0 50%;
          max-width: 50%;
        }
        @media (max-width: 370px){
          flex: 0 0 100%;
          max-width: 100%;
          margin-bottom: 3vh;
        }
        .img-container{
          width: 33%;
          margin-right: 6%;
          display: inline-block;
          @media (max-width: 370px){
            width: 20%;
          }
          img{
            max-width: 100%;
            max-height: 142px;
          }
        }
        .item-content{
          display: inline-block;
          vertical-align: top;
          max-width: 59%;
        }
      }
      &.level{
        margin-bottom: 81px;
        @media (max-width: 500px){
          margin-bottom: 10px;
        }
        h1{
          margin-bottom: 56px;
          @media (max-width: 400px){
            margin-bottom: 5vh;
          }
        }
      }
    }
  }
}