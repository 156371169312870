.thanks{
  padding-top: 47px;
  padding-bottom: 96px;
  .wide{
    .row{
      .col-md-12{
        text-align: center;
        h1{
          margin-bottom: 8px;
        }
        p, a{
          font-size: 20px;
          line-height: 24px;
          @media (max-width: 990px){
            font-size: 18px;
            line-height: 23px;
          }
          @media (max-width: 470px){
            font-size: 15px;
            line-height: 18px;
          }
        }
        p{
          color: #000000;
          margin-bottom: 64px;
        }
        a{
          display: inline-block;
          color: #0a84b6;
          text-decoration: underline #0a84b6;
        }
      }
    }
  }
}