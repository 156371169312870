section.catalog{
  padding-top: 56px;
  .wide{
    .row{
      .col-md-12{
        .head{
          color: #000000;
          font-size: 35px;
          line-height: 35px;
          font-weight: 400;
          letter-spacing: 0.87px;
          @media (max-width: 768px){
            font-size: 27px;
            line-height: 28px;
          }
          @media (max-width: 470px){
            font-size: 22px;
            line-height: 22px;
            margin-bottom: 20px;
          }
          a{
            color: #0a84b6;
            text-decoration: none;
            display: inline-block;
            border-bottom: 1px dashed #0a84b6;
          }
          p{
            display: inline-block;
          }
        }
        hr{
          width: 100%;
          height: 2px;
          background: rgba(0,0,0,.2);
          border: none;
          margin: 9px 0 57px;
        }
        .container_for_dealers{
          margin-bottom: 70px;
          a{
            max-width: 150px;
            max-height: 69px;
            display: inline-block;
            margin-right: 25px;
            margin-bottom: 55px;
            @media (max-width: 768px){
              max-width: 92px;
              max-height: 43px;
              margin-right: 18px;
              margin-bottom: 30px;
            }
            img{
              max-width: 100%;
              max-height: 100%;
            }
          }
        }
      }
      .part_of_catalog{
        margin-bottom: 106px;
        h1{
          display: block;
          margin-bottom: 28px;
        }
        .grid_sizer_catalog{
          //width: 24%;
        }
        .gutter_catalog{
          //width: .5%;
        }
        a.item_catalog {
          display: inline-flex;
          vertical-align: top;
          padding-right: 20px;
          align-items: center;
          margin-bottom: 27px;
          width: 24%;
          margin-right: .5%;
          text-decoration: none;
          @media (max-width: 991px){
            width: 33%;
            margin-right: calc(.5% - 4px);
          }
          @media (max-width: 768px){
            width: 49%;
            margin-right: calc(1% - 4px);
          }
          @media (max-width: 480px){
            display: inline-block;
          }
          @media (max-width: 350px){
            display: block;
            width: 100%;
            margin-right: 0;
          }
          .img_container{
            max-width: 50px;
            margin-right: 4px;
            width: 50px;
            height: 42px;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            img{
              max-width: 50px;
            }
          }
          p{
            font-size: 18px;
            font-weight: 700;
            line-height: 24px;
            text-decoration: underline;
            max-width: calc(100% - 54px);
            vertical-align: top;
            margin-bottom: 0;
            @media (max-width: 991px){
              font-size: 15px;
              line-height: 20px;
            }
            @media (max-width: 480px){
              max-width: 100%;
            }
          }
        }


        &.plumbing{
          .container_for_catalog_items{
            display: inline-block;
            max-width: 74.8%;
            @media (max-width: 991px){
              max-width: 100%;
              display: block;
            }
            .item_catalog{
              width: 32%;
              margin-right: .7%;
              margin-bottom: 18px;
              @media (max-width: 768px){
                width: 49%;
                margin-right: calc(1% - 4px);
              }
              @media (max-width: 350px){
                display: block;
                width: 100%;
                margin-right: 0;
              }
            }
          }
          .right_block{
            display: inline-block;
            vertical-align: top;
            width: 24%;
            @media (max-width: 991px){
              width: 100%;
              display: block;
              margin-top: 30px;
            }
            a {
              display: inline-block;
              font-size: 14px;
              font-weight: 400;
              line-height: 26.64px;
              padding-left: 13px;
              text-decoration: underline;
              @media (max-width: 768px){
                padding-left: 0;
              }
              &.main_pic{
                margin-bottom: 6px;
                padding-left: 0;
                img{
                  max-width: 100%;
                  height: 110px;
                }
              }
            }
            p{
              color: #000000;
              font-size: 21px;
              font-weight: 700;
              margin-bottom: 1px;
              padding-left: 13px;
              @media (max-width: 768px){
                padding-left: 0;
              }
            }
          }
        }

        &.stuff{
          margin-bottom: 123px;
          h1{
            margin-bottom: 12px;
          }
          .stuff_main_pic{
            display: block;
            width: 100%;
            max-width: 792px;
            margin-bottom: -19px;
            @media (max-width: 991px){
              margin-bottom: 10px;
            }
          }
          p{
            color: #000000;
            font-size: 21px;
            font-weight: 700;
            margin-bottom: 1px;
          }
          .container_for_catalog_items.stuff{
            display: inline-block;
            max-width: 74.8%;
            @media (max-width: 991px){
              max-width: 100%;
              display: block;
            }
            a{
              display: inline-block;
              vertical-align: top;
              font-size: 14px;
              font-weight: 400;
              line-height: 25.64px;
              text-decoration: underline;
              margin-bottom: 2px;
              width: 17%;
              @media (max-width: 991px){
                width: 12%;
              }
              @media (max-width: 768px){
                width: 39%;
                margin-right: calc(1% - 4px);
              }
              @media (max-width: 480px){
                width: 100%;
                margin-right: 0;
              }
              &:nth-child(2n+4), &:nth-child(2){
                width: 21%;
                @media (max-width: 768px){
                  width: 59%;
                  margin-right: calc(1% - 4px);
                }
                @media (max-width: 480px){
                  width: 100%;
                  margin-right: 0;
                }
              }
              &.third_child {
                width: calc(56% - 270px);
                @media (max-width: 991px){
                  width: calc(65% - 270px);
                }
                @media (max-width: 768px){
                  width: 39%;
                  margin-right: calc(1% - 4px);
                }
                @media (max-width: 480px){
                  width: 100%;
                  margin-right: 0;
                }
              }
              &:nth-child(4n+4){
                width: 270px;
                @media (max-width: 768px){
                  width: 59%;
                  margin-right: calc(1% - 4px);
                }
                @media (max-width: 480px){
                  width: 100%;
                  margin-right: 0;
                }
              }
            }
          }
          .right_block {
            display: inline-block;
            padding-left: 17px;
            margin-top: -8px;
            vertical-align: top;
            width: 24%;
            @media (max-width: 991px){
              width: 100%;
              display: block;
              padding-left: 0;
              margin-top: 20px;
            }
            .item_catalog{
              display: flex;
              margin-bottom: 19px;
              width: 100%;
              @media (max-width: 991px){
                display: inline-flex;
                width: 48%;
                margin-right: 1%;
              }
              @media (max-width: 480px){
                display: inline-block;
              }
              p{
                font-size: 18px;
                color: #0a84b6;
                @media (max-width: 991px){
                  font-size: 15px;
                  line-height: 20px;
                }
              }
            }
          }
        }
        &.materials{
          margin-bottom: 10px;
          h1{
            margin-bottom: 25px;
          }
        }
      }
      .materials_block{
        margin-bottom: 111px;
        @media (max-width: 991px){
          flex: 0 0 50%;
          max-width: 50%;
          margin-bottom: 30px;
        }
        @media (max-width: 480px){
          flex: 0 0 100%;
          max-width: 100%;
        }
        .big_img_container{
          height: 106px;
          overflow: hidden;
          display: flex;
          align-items: flex-end;
          margin-bottom: 9px;
          img{
            max-width: 100%;
            //width: 100%;
            max-height: 106px;
          }
        }
        p.head{
          color: #000000;
          font-size: 21px;
          font-weight: 700;
          margin-bottom: 0;
        }
        a{
          font-size: 14px;
          font-weight: 400;
          line-height: 27.64px;
          text-decoration: underline;
          display: inline-block;
        }
        .item_catalog{
          display: flex;
          margin-top: 30px;
          vertical-align: top;
          padding-right: 20px;
          align-items: center;
          margin-bottom: 30px;
          width: 100%;
          text-decoration: none;
          @media (max-width: 480px){
            display: block;
          }
          .img_container{
            max-width: 50px;
            margin-right: 4px;
            width: 50px;
            height: 42px;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            img{
              max-width: 50px;
            }
          }
          p{
            font-size: 18px;
            font-weight: 700;
            line-height: 24px;
            text-decoration: underline;
            max-width: calc(100% - 54px);
            vertical-align: top;
            margin-bottom: 0;
            @media (max-width: 991px){
              font-size: 15px;
              line-height: 20px;
            }
            @media (max-width: 480px){
              max-width: 100%;
            }
          }
        }
      }
      .container_for_slider{
        position: relative;
        @media (max-width: 1240px){
          padding: 0 30px;
        }
        @media (max-width: 990px){
          padding: 0;
        }
        .slick-prev, .slick-next{
          position: absolute;
          width: 20px;
          height: 41px;
          top: 100px;
          background: transparent;
          border-radius: 0;
          outline: none;
          border: none;
        }
        .slick-prev{
          left: -30px;
          background-image: url(../img/pics/arrow_slick_prev.png);
          background-position: 100% 50%;
          background-repeat: no-repeat;
          transition: all .5s ease;
          @media (max-width: 1240px){
            left: 10px;
          }
          &:hover{
            background-position: 0% 50%;
          }
        }
        .slick-next{
          right: -30px;
          background-image: url(../img/pics/arrow_slick_next.png);
          background-position: 0% 50%;
          background-repeat: no-repeat;
          transition: all .5s ease;
          @media (max-width: 1240px){
            right: 10px;
          }
          &:hover{
            background-position: 100% 50%;
          }
        }
      }
      .enter{
        margin-top: 45px;
        margin-bottom: 88px;
      }
      .about{
        margin-bottom: 56px;
        @media (max-width: 768px){
          margin-bottom: 30px;
        }
        h1{
          margin-bottom: 22px;
        }
        p{
          font-size: 22px;
          font-weight: 400;
          line-height: 34px;
          margin-bottom: 34px;
        }
      }
      .hint{
        padding-left: 69px;
        margin-top: 91px;
        @media (max-width: 991px){
          padding-left: 15px;
          margin-top: 70px;
        }
        @media (max-width: 768px){
          margin-top: 0;
          margin-bottom: 50px;
          padding-left: 10px;
        }
        p{
          color: #000000;
        }
      }
    }
    .slider{
      @media (max-width: 768px){
        margin-top: 50px;
      }
    }
  }
}