section.contacts{
    /*padding-top: 2rem;*/
    padding-bottom: 91px;
    .wide{
        .row{
            h1.no_underline{
                //margin-bottom: 0;
                line-height: 1.2;
                margin-top: 2.7rem;
                margin-bottom: 2.2rem;
            }

            .col-md-10{
                flex: 0 0 82.4%;
                @media (max-width: 991px){
                    flex: 0 0 100%;
                    max-width: 100%;
                }
            }

            .col-md-2{
                @media (max-width: 991px){
                    flex: 0 0 100%;
                    max-width: 100%;
                }
                .container_for_right_block{
                    justify-content: space-between;
                    flex-direction: column;
                    display: flex;
                    height: 608px;
                    @media (max-width: 991px){
                        flex-direction: row;
                        height: auto;
                        margin-top: 2rem;
                        flex-wrap: wrap;
                    }
                    .item{
                        @media (max-width: 500px){
                            width: 100%;
                            margin-bottom: 20px;
                        }
                        p{
                            margin-bottom: 0;
                            @media (max-width: 500px){
                                br{display: none;}
                            }
                            &.point{
                                margin-top: 9px;
                            }
                        }
                        p.head{
                            margin-bottom: .8rem;
                            font-size: 22px;
                            font-weight: 800;
                            line-height: 1;
                            @media (max-width: 991px){
                            //min-height: 52px;
                            }
                            @media (max-width: 475px){
                            font-size: 18px;
                            line-height: 20px;
                            }
                        }
                    }
                }
            }
        }

        .data-form{
            width: 100%;
            margin-top: 1.0rem;
            height: auto;
            .col-md-6{
                flex: 0 0 51.1%;
                max-width: 51.1%;
                @media (max-width: 767px){
                    flex: 0 0 100%;
                    max-width: 100%;
                }
            }

            p{
                color: #000000;
                font-weight: 900;
                line-height: 14px;
                margin-bottom: 5px;
                margin-left: 15px;
                @media (max-width: 400px){
                    font-size: 11px;
                }
            }
            .form-group{
                width: 100%;
                margin-bottom: 1.6rem;
                @media (max-width: 400px){
                    margin-bottom: 15px;
                }
            }

            input{
                width: 100%;
                max-width: 224px;
                height: 44px;
                padding-left: 16px;
                box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.2);
                outline: none;
                border: 1px solid #cccccc;
                color: #000000;
                font-size: 18px;
                font-weight: 400;
                @media (max-width: 400px){
                    max-width: 100%;
                    font-size: 14px;
                }
                &[type=email]{
                    max-width: 100%;
                }
            }
            textarea{
                box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.2);
                outline: none;
                border: 1px solid #cccccc;
                max-width: 100%;
                padding: 8px 16px;
                width: 100%;
                color: #000000;
                font-size: 18px;
                font-weight: 400;
                line-height: 21px;
                @media (max-width: 400px){
                    font-size: 14px;
                    line-height: 16px;
                    max-height: 111px;
                }
            }
            button{
                margin-top: -15px;
                width: 173px;
                display: inline-block;
                height: 48px;
                background-color: #ffde00;
                color: #000000;
                font-size: 22px;
                font-weight: 700;
                line-height: 28px;
                outline: none;
                border: none;
                cursor: pointer;
                @media (max-width: 400px){
                    width: 100%;
                    font-size: 14px;
                    line-height: 14px;
                    height: 39px;
                    margin-top: 0;
                }
            }
        }
    }
}
