section.dry_mixes, section.kit{
  margin-bottom: 80px;
  @media (max-width: 990px){
    margin-bottom: 70px;
  }
  @media (max-width: 768px){
    margin-bottom: 50px;
  }
  .grid_sizer2, .item_good{
    width: 25%;
    @media (max-width: 990px){
      width: 49%;
      display: inline-block;
      vertical-align: top;
    }
    @media (max-width: 420px){
      //min-width: 200px;
            width: 100%;
            max-width: 100%;
            //margin-right: 20px;
    }
  }
  .left_item{
    width: 22%;
    @media (max-width: 990px){
      width: 49%;
      display: inline-block;
      vertical-align: top;
    }
    @media (max-width: 420px){
      //min-width: 200px;
            width: 100%;
            max-width: 100%;
            //margin-right: 20px;
    }
  }
  .item_good{
    height: 167px;
    margin-bottom: 10px;
    @media (max-width: 990px){
      max-height: unset;
      height: auto;
      margin-bottom: 35px;
      //height: 153px;
    }
    &.left_item{
      height: auto;
      @media (max-width: 990px){
        height: auto;
        max-height: none;
      }
    }
  }
  .item--width3{
    width: 75%;
    text-align: right;
    margin-top: 8px;
    padding-right: 25px;
    min-height: unset;
    height: auto;
    @media (max-width: 990px){
      width: 100%;
      text-align: left;
      height: auto;
    }
    @media (max-width: 500px){
      display: none;
    }
  }
  .gutter2{
    width: 0;
  }
  @media (max-width: 420px){
    .grid_container{
      //max-width: 100%;
      //overflow-x: auto;
    }
    .grid{
      //min-width: 900px;
      //max-width: none;
    }
    .col-md-12{
      position: relative;
      &::after{
        /*content: "";
        width: 50px;
        height: 100%;
        background: linear-gradient(to right, transparent 0%, white 100%);
        position: absolute;
        top: 0;
        right: 0;*/
      }
    }
  }
}

section.kit{
  margin-bottom: 42px;
  .item_good{
    margin-bottom: 60px;
    height: 116px;
    @media (max-width: 990px){
      max-height: unset;
      height: auto;
      margin-bottom: 35px;
    }
    @media (max-width: 768px){
      //height: 153px;
      //max-height: 153px;
    }
    &.left_item{
      height: auto;
      @media (max-width: 990px){
        height: auto;
      }
      @media (max-width: 768px){
        //height: 153px;
        //max-height: 153px;
      }
    }
  }
  .item--width3{
    width: 75%;
    text-align: right;
    height: auto;
    @media (max-width: 990px){
      height: auto;
      text-align: left;
    }
    @media (max-width: 768px){
      //display: none;
    }
  }
}

