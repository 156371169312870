.item-container{
  .item-content{
    .name{
      text-decoration: underline solid #0587ba;
      margin-bottom: 10px;
      line-height: 20px;
      display: block;
      font-size: 14px;
      @media (max-width: 470px){
        font-size: 11px;
        line-height: 14px;
      }
      span{
        letter-spacing: 1.4px;
      }
    }
    .price{
      color: #000000;
      font-weight: 700;
      line-height: 14px;
      &::after{
        content: "₽";
        display: inline-block;
        margin-left: 1px;
      }
      @media (max-width: 470px){
        font-size: 12px;
        line-height: 12px;
      }
    }
    .button_container{
      &.amount_mode{
        button.to_cart{
          display: none;
        }
        .amount{
          display: block;
        }
      }
      button.to_cart{
        width: 103px;
        padding-top: 0;
        padding-bottom: 0;
        border: none;
        border-radius: 0;
        height: 28px;
        color: #000000;
        font-weight: 700;
        outline: none;
        text-align: left;
        padding-left: 10px;
        line-height: 12px;
        background-color: #ffde00;
        position: relative;
        &::before{
          content: "";
          position: absolute;
          top: 0;
          right: -10px;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 14px 0 14px 10px;
          border-color: transparent transparent transparent #ffde00;
        }
        &::after{
          content: "";
          background: url(../img/pics/cart_mini.png) no-repeat;
          width: 17px;
          height: 16px;
          display: inline-block;
          margin-left: 4px;
          vertical-align: sub;
        }
        &:focus{
          border: none;
          outline: none;
          box-shadow: none;
        }
      }
      .amount{
        display: none;
        max-width: 115px;
        .item-quantity{
          width: 49px;
          height: 28px;
          border: 1px solid #c1c1c1;
          background-color: #ffffff;
          text-align: center;
          display: inline-block;
          @media (max-width: 420px){
            width: 40%;
          }
        }
        .increase, .decrease{
          width: 19px;
          height: 28px;
          display: inline-block;
          background-color: #ffde00;
          vertical-align: top;
          border: none;
          outline: none;
          position: relative;
          color: #000000;
          font-weight: 700;
          cursor: pointer;
          padding: 0;
          text-align: center;
        }
        .increase{
          padding-left: 4px;
        }
        .increase::after{
          content: "";
          position: absolute;
          top: 0;
          right: -9px;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 14px 0 14px 10px;
          border-color: transparent transparent transparent #ffde00;
        }
        .decrease{
          padding-right: 4px;
          margin-left: 10px;
        }
        .decrease::before{
          content: "";
          position: absolute;
          top: 0;
          left: -10px;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 14px 10px 14px 0;
          border-color: transparent #ffde00 transparent transparent;
        }
      }
    }
  }
  &.big_one{
    .img-container{
      width: 100%;
      height: 100%;
      min-height: 283px;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      margin-bottom: 12px;
      @media (max-width: 990px){
        width: 30%;
        height: auto;
        display: inline-flex;
        overflow: hidden;
        justify-content: flex-start;
        align-items: center;
        vertical-align: top;
        margin-bottom: 0;
        min-height: unset;
        max-height: 153px;
      }
      &.smaller_than_usually{
        height: 260px;
        min-height: unset;
        @media (max-width: 990px){
          height: auto;
        }
      }
      &.never{
        height: 320px;
        @media (max-width: 990px){
          height: auto;
        }
      }
      img{
        max-height: 100%;
        max-width: 100%;
        @media (max-width: 990px){
          max-height: 153px;
          width: auto;
        }
      }
    }
    .item-content{
      @media (max-width: 990px){
        display: inline-block;
        max-width: 69%;
        padding-left: 4%;
        padding-right: 4%;
      }
      @media (max-width: 768px){
        width: 65%;
      }
      .name{
        text-decoration: underline solid #0587ba;
      }
      .price{
        margin-top: 6px;
        display: inline-block;
        @media (max-width: 990px){
          display: block;
          margin-top: 0;
        }
      }
    }
    .button_container{
      float: right;
      margin-right: 22px;
      @media (max-width: 990px){
        float: none;
        margin-top: 10px;
      }
    }
  }
  &.small_one{
    display: inline-block;
    vertical-align: top;
    padding: 0;
    //margin-bottom: 20px;
    width: 100%;
    .img-container{
      width: 39%;
      height: auto;
      max-height: 167px;
      display: inline-flex;
      overflow: hidden;
      justify-content: center;
      align-items: center;
      vertical-align: top;
      margin-bottom: 0;
      @media (max-width: 990px){
        width: 30%;
        justify-content: flex-start;
        max-height: 153px;
      }
      img{
        height: 100%;
        max-width: 100%;
      }
    }
    .item-content{
      display: inline-block;
      max-width: 59%;
      @media (max-width: 990px){
        max-width: 69%;
        padding-left: 4%;
        padding-right: 4%;
      }
      @media (max-width: 768px){
        width: 65%;
      }
      .price{
        display: block;
      }
      .button_container{
        margin-top: 8px;
        margin-left: -5px;
      }
    }
  }
}