section.delivery{
  padding-top: 29px;
  padding-bottom: 93px;
  .wide{
    .row{
      justify-content: space-between;
      h1{
        margin-bottom: 31px;
      }
      .col-md-10{
        flex: 0 0 81%;
        max-width: 81%;
        @media (max-width: 1092px){
          flex: 0 0 75%;
          max-width: 75%;
        }
        @media (max-width: 990px){
          flex: 0 0 100%;
          max-width: 100%;
        }
        .select_wrapper{
          position: relative;
          max-width: 323px;
          width: 100%;
          margin-left: 12px;
          display: inline-block;
          @media (max-width: 990px){
            max-width: 40%;
            min-width: 208px;
          }
          @media (max-width: 400px){
            max-width: calc(100% - 142px);
            height: 45px;
            min-width: unset;
          }
          &::after{
            content: "";
            width: 24px;
            height: 16px;
            background: url(../img/pics/arrow_down.png) no-repeat;
            position: absolute;
            top: calc(50% - 6px);
            right: 32px;
            z-index: -1;
            @media (max-width: 768px){
              width: 16px;
              height: 9px;
              background-size: contain;
              top: calc(50% - 2px);
              right: 15px;
            }
          }
          select{
            width: 100%;
            display: inline-block;
            height: 79px;
            border: 1px solid #cccccc;
            box-shadow: inset 0 2px 0 rgba(0, 0, 0, 0.2);
            font-size: 54px;
            line-height: 52px;
            outline: none;
            background: transparent;
            font-weight: 700;
            padding: 0 62px 0 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            @media (max-width: 990px){
              font-size: 35px;
              line-height: 35px;
              height: 65px;
            }
            @media (max-width: 768px){
              font-size: 27px;
              line-height: 28px;
              height: 45px;
            }
            @media (max-width: 470px){
              font-size: 22px;
              line-height: 22px;
              margin-bottom: 20px;
              padding-left: 10px;
            }
            option{
              font-size: 22px;
              line-height: 22px;
              @media (max-width: 768px){
                font-size: 16px;
                line-height: 16px;
              }
            }
          }
        }
        .map_container{
          max-width: 100%;
          #map{
            height: 695px;
            max-width: 100%;
            @media (max-width: 600px){
              height: 53vw;
            }
          }
        }
        .text_container{
          margin-top: 58px;
          p{
            font-size: 22px;
            font-weight: 400;
            line-height: 34px;
            margin-bottom: 34px;
            max-width: 821px;
            @media (max-width: 768px){
              font-size: 18px;
              line-height: 25px;
            }
            @media (max-width: 500px){
              font-size: 15px;
              line-height: 19px;
            }
          }
        }
      }

      .col-md-2{
        flex: 0 0 19%;
        max-width: 19%;
        padding-right: 0;
        padding-top: 90px;
        @media (max-width: 1092px){
          flex: 0 0 25%;
          max-width: 25%;
        }
        @media (max-width: 990px){
          flex: 0 0 100%;
          max-width: 100%;
          padding-right: 15px;
        }
        .delivery_item{
          margin-bottom: 7px;
          display: inline-block;
          vertical-align: top;
          padding: 10px 15px;
          transition: all .6s ease;
          width: auto;
          @media (max-width: 990px){
            width: 49%;
          }
          @media (max-width: 475px){
            padding: 10px;
          }
          &:hover{
            background-color: #ffe71d;
            .row_item{
              &::before{
                //z-index: 0;
              }
              p{
                background-color: #ffe71d;
                z-index: 2;
              }
            }
          }
          p.head{
            font-size: 22px;
            font-weight: 700;
            line-height: 26px;
            @media (max-width: 990px){
              min-height: 52px;
            }
            @media (max-width: 475px){
              font-size: 18px;
              line-height: 20px;
            }
          }
          .point{
            width: 100%;
            &.noted{
              .row_item{
                display: inline-block;
              }
              p.note{
                display: inline-block;
                margin-left: 1px;
                @media (max-width: 475px){
                  display: block;
                  max-width: 100px;
                  text-align: right;
                  margin-top: -4px;
                }
              }
            }
          }
          .row_item{
            position: relative;
            background-image: url(../img/pics/dots.png);
            background-repeat: repeat-x;
            background-position: 0 40%;
            width: 105px;
            &::before{
              //content: ". . . . . . . . . . . . . . . . . . . . . . . . . . . .";
              //font-size: 10px;
              //position: absolute;
              //white-space: nowrap;
              //bottom: 6px;
              //left: 0;
              //max-width: 100%;
              //overflow: hidden;
              //letter-spacing: 0.25px;
              //z-index: -1;
            }
            p{
              transition: all .6s ease;
              margin-bottom: 2px;
              display: inline-block;
              background: white;
             // margin-bottom: 0;
              &.weight{
                max-width: 49px;
                width: auto;
                font-weight: 800;
              }
              &.price{
                float: right;
                position: relative;
                max-width: calc(100% - 47px);
              }
            }
          }
        }
      }


      .col-md-4{
        margin-top: 59px;
        @media (max-width: 768px){
          margin-top: 40px;
        }
        p{
          font-size: 16px;
          font-weight: 400;
          margin-bottom: 10px;
          line-height: 24px;
          &.name{
            font-weight: 800;
          }
        }
      }
    }
  }
}