section.cart{
  padding-top: 52px;
  .wide{
    .row{
      .col-md-9{
        margin-bottom: 147px;
        min-width: 866px;
        @media (max-width: 990px){
          min-width: 100%;
        }
        @media (max-width: 500px){
          margin-bottom: 100px;
        }
      }
      h1.cart_head{
        margin-bottom: 29px;
        &::before{
          content: "";
          width: 48px;
          height: 48px;
          background: url(../img/pics/cart_icon_big.png) no-repeat;
          background-size: contain;
          display: inline-block;
          margin-right: 11px;
          position: relative;
          top: 3px;
          @media (max-width: 768px){
            width: 30px;
            height: 30px;
          }
        }
      }
      .table_container{
        table{
          min-width: 866px;
          border-collapse: collapse;
          @media (max-width: 990px){
            min-width: 100%;
          }
          @media (max-width: 768px){
            display: block;
            width: 100%;
            tbody{
              display: block;
              width: 100%;
            }
          }
          tr{
            position: relative;
            transition: all .5s ease;
            @media (max-width: 768px){
              display: block;
              width: 100%;
              margin-bottom: 35px;
            }
            &.hover{
              color: #ff2828;
              transition: all .5s ease;
              td{
                color: #ff2828;
                .delete{
                  color: #ff2828;
                }
                .amount{
                  .item-quantity, .increase, .decrease{
                    color: #ff2828;
                  }
                }
              }
            }
            &.deleted{
              display: none;
            }
            &.sum{
              font-weight: 700;
              td{
                height: 84px;
                @media (max-width: 768px){
                  height: auto;
                  &.right_align{
                    text-align: left;
                    display: inline-block;
                  }
                  &.price{
                    display: inline-block;
                    border-top: 0;
                  }
                }
              }
              p{
                display: inline-block;
              }
              input{
                width: 99px;
                height: 38px;
                border: 1px solid #c1c1c1;
                background-color: #ffffff;
                display: inline-block;
                padding: 0 10px;
                border-radius: 0;
              }
            }
            th, td{
              padding-left: 5px;
            }
            .right_align{
              text-align: right;
              padding-right: 31px;
              &.price{
                padding-right: 0;
                @media (max-width: 768px){
                  display: none;
                }
              }
            }
            .one_price{
              @media (max-width: 990px){
                display: none;
              }
              @media (max-width: 768px){
                display: block;
                width: calc(100% - 67px);
                margin-left: 50px;
                padding: 0;
                background: none;
              }
            }
            .weight{
              @media (max-width: 768px){
                display: none;
              }
            }
            th{
              height: 58px;
              font-size: 20px;
              padding-bottom: 9px;
              padding-right: 28px;
              @media (max-width: 990px){
                padding-right: 5px;
              }
              @media (max-width: 768px){
                display: none;
              }
              &.bordered{
                position: relative;
                //border-bottom: 2px solid #000000;
                &::after{
                  content: "";
                  width: 100%;
                  height: 2px;
                  background: #000;
                  bottom: 11px;
                  left: 0;
                  position: absolute;
                }
                &.amount{
                  padding-left: 23px;
                }
              }
              &.one_price {
                @media (max-width: 768px) {
                  display: none;
                }
              }
            }
            td{
              height: 70px;
              transition: all .5s ease;
              color: black;
              font-size: 20px;
              font-weight: 400;
              padding-right: 15px;
              @media (max-width: 768px){
                display: block;
                height: 100%;
              }
              &.border_top{
                position: relative;
                color: #ff2828;
                &::after{
                  content: "";
                  width: 100%;
                  height: 2px;
                  background: #000;
                  top: 13px;
                  left: 0;
                  position: absolute;
                }
              }
              &.name{
                width: 43.8%;
                padding-bottom: 9px;
                background: linear-gradient(#ccc 0, white 1px);
                background-repeat: repeat-x;
                background-size: 2px 2px;
                background-position: 0 68%;
                @media (max-width: 990px){
                  width: 44.5%;
                }
                @media (max-width: 768px){
                  display: block;
                  width: calc(100% - 67px);
                  margin-left: 50px;
                  padding: 0;
                  background: none;
                }
                p{
                  display: block;
                  font-size: 20px;
                  font-weight: 700;
                  letter-spacing: 0.5px;
                  line-height: 20px;
                  margin-bottom: 0;
                  background: white;
                  @media (max-width: 990px){
                    font-size: 18px;
                  }
                  @media (max-width: 768px){
                    margin-bottom: 4px;
                    display: block;
                    background: transparent;
                  }
                  @media (max-width: 500px){
                    font-size: 16px;
                  }
                }
                span{
                  display: inline-block;
                  background: white;
                  font-size: 14px;
                  font-weight: 400;
                  letter-spacing: 0.35px;
                  line-height: 14px;
                  margin-bottom: 0;
                  @media (max-width: 990px){
                    font-size: 12px;
                  }
                  @media (max-width: 500px){
                    font-size: 11px;
                    display: block;
                    background: transparent;
                  }
                }
              }
              &.amount{
                padding-left: 0;
                @media (max-width: 768px){
                  display: block;
                  width: calc(100% - 67px);
                  margin-left: 50px;
                  padding: 0;
                  background: none;
                }
              }
              &.img{
                display: block;
                padding-left: 0;
                padding-right: 2px;
                @media (max-width: 768px){
                  float: left;
                  position: absolute;
                  top: 0;
                  left: 0;
                }
              }
              .img_container{
                height: 45px;
                display: flex;
                align-items: flex-start;
                justify-content: center;
                width: 45px;
                img{
                  max-width: 100%;
                  max-height: 100%;
                }
              }
              .amount{
                margin-right: 20px;
                margin-left: -5px;
                max-width: 115px;
                @media (max-width: 990px){
                  margin-right: 0;
                }
                .item-quantity{
                  width: 49px;
                  height: 28px;
                  border: 1px solid #c1c1c1;
                  background-color: #ffffff;
                  text-align: center;
                  display: inline-block;
                  transition: all .5s ease;
                }
                .increase, .decrease{
                  width: 19px;
                  height: 28px;
                  display: inline-block;
                  background-color: #eceaec;
                  vertical-align: top;
                  border: none;
                  outline: none;
                  position: relative;
                  color: #000000;
                  font-weight: 700;
                  line-height: 20px;
                  cursor: pointer;
                  padding: 0;
                  text-align: center;
                  transition: all .5s ease;
                  &:hover{
                    background-color: #ffde00;
                  }
                  @media (max-width: 768px){
                    background-color: #ffde00;
                    padding: 0 2px;
                  }
                }
                .increase{
                  padding-left: 3px;
                  &:hover{
                    &::after{
                      border-color: transparent transparent transparent #ffde00;
                    }
                  }
                }
                .decrease{
                  padding-right: 3px;
                  margin-left: 10px;
                  &:hover{
                    &::before{
                      border-color: transparent #ffde00 transparent transparent;
                    }
                  }
                }
                .increase::after{
                  transition: all .5s ease;
                  content: "";
                  position: absolute;
                  top: 0;
                  right: -9px;
                  width: 0;
                  height: 0;
                  border-style: solid;
                  border-width: 14px 0 14px 10px;
                  border-color: transparent transparent transparent #eceaec;
                  @media (max-width: 768px){
                    border-color: transparent transparent transparent #ffde00;
                  }
                }
                .decrease::before{
                  transition: all .5s ease;
                  content: "";
                  position: absolute;
                  top: 0;
                  left: -10px;
                  width: 0;
                  height: 0;
                  border-style: solid;
                  border-width: 14px 10px 14px 0;
                  border-color: transparent #eceaec transparent transparent;
                  @media (max-width: 768px){
                    border-color: transparent #ffde00 transparent transparent;
                  }
                }
              }
              .delete{
                transition: all .5s ease;
                color: #d2d1d2;
                font-size: 35px;
                text-decoration: none;
                margin-left: 33px;
                @media (max-width: 990px){
                  margin-left: 15px;
                }
                @media (max-width: 768px){
                  float: right;
                  position: absolute;
                  top: -13px;
                  right: 0;
                  margin: 0;
                }
              }
            }
          }
        }
      }

      a.continue_shopping, a.continue_order{
        width: 48%;
        display: inline-block;
        height: 61px;
        font-size: 20px;
        font-weight: 700;
        line-height: 58px;
        text-align: center;
        text-decoration: none;
        margin-top: 36px;
        @media (max-width: 768px){
          height: 50px;
          line-height: 48px;
          margin-top: 0;
        }
      }
      a.continue_shopping{
        background-color: rgba(65,63,67,.2);
        max-width: 282px;
        color: #ffffff;
        margin-left: 15px;
        @media (max-width: 768px){
          margin-left: 0;
          min-width: 235px;
        }
        @media (max-width: 500px){
          width: 100%;
          max-width: unset;
        }
      }
      a.continue_order{
        max-width: 242px;
        background-color: #ffde00;
        float: right;
        color: #000000;
        margin-right: 35px;
        @media (max-width: 768px){
          margin-right: 0;
          max-width: 200px;
        }
        @media (max-width: 500px){
          float: none;
          width: 100%;
          max-width: unset;
          margin-top: 20px;
        }
      }

      .slider{
        a.hide{
          color: #0a84b6;
          font-size: 16px;
          font-weight: 700;
          line-height: 40px;
          text-decoration: underline;
          letter-spacing: 0.4px;
          margin-left: 40px;
          display: inline-block;
        }
      }
    }
  }
}