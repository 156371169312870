section.b2b{
  padding-top: 44px;
  .wide{
    .row{
      justify-content: space-between;
      h1{
        margin-bottom: 22px;
      }
      .wrapper{
        max-width: 890px;
        width: 100%;
        margin-bottom: 138px;
        @media (max-width: 990px){
          margin-bottom: 70px;
        }
        form{
          display: inline-block;
          width: 645px;
          @media (max-width: 990px){
            width: 100%;
          }
          p{
            font-weight: 800;
            margin-bottom: 0;
            margin-left: 18px;
          }
          input{
            width: 70%;
            display: inline-block;
            outline: none;
            border: 1px solid #ccc;
            box-shadow: inset 0 2px 0 rgba(0, 0, 0, 0.3);
            max-width: 615px;
            padding-left: 17px;
            color: #000000;
            height: 74px;
            font-size: 34px;
            font-weight: 400;
            @media (max-width: 990px){
              font-size: 25px;
              line-height: 25px;
            }
            @media (max-width: 768px){
              font-size: 17px;
              line-height: 17px;
              width: calc(100% - 85px);
              height: 50px;
            }
            &::placeholder{
              opacity: 1;
              color: #000000;
            }
          }
          button{
            display: inline-block;
            width: 30%;
            height: 74px;
            background-color: #ffde00;
            color: #000000;
            font-weight: 700;
            font-size: 40px;
            line-height: 40px;
            text-align: center;
            border: none;
            margin-left: -6px;
            position: relative;
            top: 2px;
            outline: none;
            @media (max-width: 990px){
              line-height: 24px;
              font-size: 24px;
              top: -1px;
            }
            @media (max-width: 768px){
              width: 85px;
              font-size: 18px;
              line-height: 17px;
              height: 50px;
              top: 1px;
            }
          }
        }
        p.hint{
          display: inline-block;
          max-width: 183px;
          line-height: 20px;
          margin-left: 32px;
          color: #000000;
          @media (max-width: 990px){
            max-width: 100%;
            margin-top: 15px;
          }
          a{
            color: #0f81b2;
            text-decoration: underline;
          }
        }
      }
      .col-md-9{
        @media (max-width: 990px){
          flex: 0 0 100%;
          max-width: 100%;
        }
        h1{
          margin-bottom: 26px;
        }
      }
      article{
        max-width: 874px;
        margin-bottom: 45px;
        @media (max-width: 990px){
          margin-bottom: 50px;
        }
        p{
          color: #000000;
          font-size: 22px;
          margin-bottom: 12px;
          line-height: 34px;
          @media (max-width: 990px){
            font-size: 20px;
            line-height: 28px;
          }
          @media (max-width: 768px){
            font-size: 18px;
            line-height: 26px;
          }
          @media (max-width: 470px){
            font-size: 16px;
            line-height: 23px;
          }
        }
      }
      .col-md-2{
        flex: 0 0 18.5%;
        margin-top: 95px;
        max-width: 18.5%;
        @media (max-width: 990px){
          flex: 0 0 100%;
          max-width: 100%;
          margin-bottom: 50px;
          margin-top: 50px;
        }
        p{
          line-height: 20px;
          margin-bottom: 10px;
        }
      }
    }
  }
}