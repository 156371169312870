section.text_page{
  padding-top: 50px;
  &.conditions {
    padding-top: 42px;
    .wide .row {
      .col-md-9 {
        h1.no_underline {
          &:nth-of-type(2) {
            margin-bottom: 25px;
          }
          &:first-of-type {
            margin-bottom: 25px;
          }
        }
        p {
          margin-bottom: 34px;
        }
      }
      .col-md-3 {
        padding-left: 6px;
        @media (max-width: 768px){
          padding-left: 10px;
        }
        .container_for_right_block p.head {
          line-height: 16px;
        }
      }
    }
  }
  &.payment {
    padding-top: 41px;
    margin-bottom: 90px;
    .wide .row  {
      .col-md-9 {
        p {
          margin-bottom: 35px;
        }
        h1.no_underline:first-of-type {
          margin-top: 3px;
        }
      }
      .col-md-3 {
        margin-top: 0;
        .container_for_right_block .item.payment_page {
          margin-bottom: 38px;
          padding-left: 8px;
          p {
            padding-left: 4px;
            line-height: 20px;
            &:nth-child(2) {
              margin-bottom: 0;
            }
          }
          .img_container {
            margin-bottom: 6px;
            min-height: 87px;
          }
        }
      }
    }
  }
  .wide{
    .row{
      .col-md-9{
        padding-right: 30px;
        @media (max-width: 768px){
          padding-right: 15px;
          flex: 0 0 100%;
          max-width: 100%;
        }
        h1.no_underline{
          margin-top: 100px;
          @media (max-width: 768px){
            margin-top: 50px;
          }
          &:first-of-type{
            margin-top: 0;
          }
          &.payment_page{
            margin-top: 51px;
            margin-bottom: 23px;
            @media (max-width: 768px){
              margin-top: 30px;
            }
          }
        }
        p{
          font-size: 22px;
          line-height: 34px;
          margin-bottom: 30px;
          @media (max-width: 768px){
            font-size: 18px;
            line-height: 25px;
          }
          @media (max-width: 500px){
            font-size: 15px;
            line-height: 19px;
          }
        }
        ul{
          padding-left: 15px;
          &.payment_page {
            padding-left: 6px;
            li {
              margin-bottom: 16px;
            }
          }
          li{
            list-style-type: none;
            font-size: 22px;
            line-height: 24px;
            margin-bottom: 17px;
            position: relative;
            &::before{
              content: "·";
              position: absolute;
              top: 0;
              left: -15px;
            }
            @media (max-width: 768px){
              font-size: 18px;
              line-height: 25px;
            }
            @media (max-width: 500px){
              font-size: 15px;
              line-height: 19px;
            }
          }
        }
      }
      .col-md-3{
        margin-top: 17px;
        @media (max-width: 768px){
          flex: 0 0 100%;
          max-width: 100%;
        }
        .container_for_right_block{
          max-width: 190px;
          float: right;
          @media (max-width: 768px){
            float: none;
            max-width: 100%;
            margin-top: 0;
          }
          p{
            &.head{
              margin-bottom: 32px;
              font-weight: 900;
            }
            &.h1{
              font-size: 72px;
              color: #453e42;
              line-height: 54px;
              display: inline-block;
              margin-bottom: 10px;
              font-weight: 900;
              span{
                font-size: 20px;
                margin-left: -10px;
              }
            }
          }
          .img_valday{
            max-width: 42px;
            display: inline-block;
            vertical-align: top;
            margin-right: 6px;
          }
          .item{
            margin-bottom: 32px;
            @media (max-width: 768px){
              width: 47%;
              display: inline-block;
              vertical-align: top;
              &:nth-child(2n+1){
                margin-right: 3%;
              }
            }
            @media (max-width: 359px){
              width: 100%;
              text-align: center;
              &:nth-child(2n+1){
                margin-right: 0;
              }
            }
            &.conditions_page{
              margin-bottom: 30px;
              &:nth-child(3) {
                margin-bottom: 3em;
              }
              p.h1 {
                margin-bottom: -8px;
              }
              p:last-of-type{
                line-height: 17px;
                @media (max-width: 768px){
                  br{
                    display: none;
                  }
                }
              }
            }
            &.payment_page{
              padding-right: 20px;
              @media (max-width: 768px){
                padding-right: 0;
              }
              .img_container {
                margin-bottom: 15px;
                @media (max-width: 768px) {
                  min-height: 100px;
                  display: flex;
                  align-items: flex-end;
                }
                @media (max-width: 359px){
                  justify-content: center;
                }
              }
              p{
                margin-bottom: 5px;
              }
            }
          }
          .note{
            margin-top: 14.4em;
            padding-left: 7px;
            @media (max-width: 768px){
              margin-top: 0;
              margin-bottom: 30px;
              padding-left: 0;
            }
            p{
              margin-bottom: 30px;
            }
          }
        }
      }
    }
  }
}