section.promo{
  padding-top: 41px;
  .wide{
    .row{
      h1{
        margin-bottom: 36px;
      }
      .container_for_promo{
        margin-bottom: -46px;
      }
      .grid_sizer{
        width: 29.3%;
        @media (max-width: 990px){
          display: none;
        }
      }
      .gutter_promo{
        width: 2.6%;
        @media (max-width: 990px){
          display: none;
        }
      }
      .sale {
        width: 29.3%;
        margin-bottom: 70px;
        max-width: 100%;
        @media (max-width: 990px){
          width: 100%;
        }
      }
    }
  }
}