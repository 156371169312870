@font-face {
  font-weight : 200;

  font-family : "SFUIDisplay";
  src : url("/css/fonts/SFUIDisplay/ultralight/SFUIDisplay-Ultralight.eot?#iefix") format("embedded-opentype"), local("☺"), url("/css/fonts/SFUIDisplay/ultralight/SFUIDisplay-Ultralight.woff") format("woff"), url("/css/fonts/SFUIDisplay/ultralight/SFUIDisplay-Ultralight.ttf") format("truetype"), url("/css/fonts/SFUIDisplay/ultralight/SFUIDisplay-Ultralight.svg#SFUIDisplay") format("svg");
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-weight : 200;

    font-family : "SFUIDisplay";
    src : url("/css/fonts/SFUIDisplay/ultralight/SFUIDisplay-Ultralight.svg#SFUIDisplay") format("svg");
  }
}

@font-face {
  font-weight : 300;

  font-family : "SFUIDisplay";
  src : url("/css/fonts/SFUIDisplay/light/SFUIDisplay-Light.eot?#iefix") format("embedded-opentype"), local("☺"), url("/css/fonts/SFUIDisplay/light/SFUIDisplay-Light.woff") format("woff"), url("/css/fonts/SFUIDisplay/light/SFUIDisplay-Light.ttf") format("truetype"), url("/css/fonts/SFUIDisplay/light/SFUIDisplay-Light.svg#SFUIDisplay") format("svg");
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-weight : 300;

    font-family : "SFUIDisplay";
    src : url("/css/fonts/SFUIDisplay/light/SFUIDisplay-Light.svg#SFUIDisplay") format("svg");
  }
}

@font-face {
  font-weight : 400;

  font-family : "SFUIDisplay";
  src : url("/css/fonts/SFUIDisplay/regular/SFUIDisplay-Regular.eot?#iefix") format("embedded-opentype"), local("☺"), url("/css/fonts/SFUIDisplay/regular/SFUIDisplay-Regular.woff") format("woff"), url("/css/fonts/SFUIDisplay/regular/SFUIDisplay-Regular.ttf") format("truetype"), url("/css/fonts/SFUIDisplay/regular/SFUIDisplay-Regular.svg#SFUIDisplay") format("svg");
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-weight : 400;

    font-family : "SFUIDisplay";
    src : url("/css/fonts/SFUIDisplay/regular/SFUIDisplay-Regular.svg#SFUIDisplay") format("svg");
  }
}

@font-face {
  font-weight : 500;

  font-family : "SFUIDisplay";
  src : url("/css/fonts/SFUIDisplay/medium/SFUIDisplay-Medium.eot?#iefix") format("embedded-opentype"), local("☺"), url("/css/fonts/SFUIDisplay/medium/SFUIDisplay-Medium.woff") format("woff"), url("/css/fonts/SFUIDisplay/medium/SFUIDisplay-Medium.ttf") format("truetype"), url("/css/fonts/SFUIDisplay/medium/SFUIDisplay-Medium.svg#SFUIDisplay") format("svg");
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-weight : 500;

    font-family : "SFUIDisplay";
    src : url("/css/fonts/SFUIDisplay/medium/SFUIDisplay-Medium.svg#SFUIDisplay") format("svg");
  }
}

@font-face {
  font-weight : 600;

  font-family : "SFUIDisplay";
  src : url("/css/fonts/SFUIDisplay/semibold/SFUIDisplay-Semibold.eot?#iefix") format("embedded-opentype"), local("☺"), url("/css/fonts/SFUIDisplay/semibold/SFUIDisplay-Semibold.woff") format("woff"), url("/css/fonts/SFUIDisplay/semibold/SFUIDisplay-Semibold.ttf") format("truetype"), url("/css/fonts/SFUIDisplay/semibold/SFUIDisplay-Semibold.svg#SFUIDisplay") format("svg");
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-weight : 600;

    font-family : "SFUIDisplay";
    src : url("/css/fonts/SFUIDisplay/semibold/SFUIDisplay-Semibold.svg#SFUIDisplay") format("svg");
  }
}

@font-face {
  font-weight : 700;

  font-family : "SFUIDisplay";
  src : url("/css/fonts/SFUIDisplay/bold/SFUIDisplay-Bold.eot?#iefix") format("embedded-opentype"), local("☺"), url("/css/fonts/SFUIDisplay/bold/SFUIDisplay-Bold.woff") format("woff"), url("/css/fonts/SFUIDisplay/bold/SFUIDisplay-Bold.ttf") format("truetype"), url("/css/fonts/SFUIDisplay/bold/SFUIDisplay-Bold.svg#SFUIDisplay") format("svg");
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-weight : 700;

    font-family : "SFUIDisplay";
    src : url("/css/fonts/SFUIDisplay/bold/SFUIDisplay-Bold.svg#SFUIDisplay") format("svg");
  }
}

@font-face {
  font-weight : 800;

  font-family : "SFUIDisplay";
  src : url("/css/fonts/SFUIDisplay/black/SFUIDisplay-Black.eot?#iefix") format("embedded-opentype"), local("☺"), url("/css/fonts/SFUIDisplay/black/SFUIDisplay-Black.woff") format("woff"), url("/css/fonts/SFUIDisplay/black/SFUIDisplay-Black.ttf") format("truetype"), url("/css/fonts/SFUIDisplay/black/SFUIDisplay-Black.svg#SFUIDisplay") format("svg");
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-weight : 800;

    font-family : "SFUIDisplay";
    src : url("/css/fonts/SFUIDisplay/black/SFUIDisplay-Black.svg#SFUIDisplay") format("svg");
  }
}

@font-face {
  font-weight : 900;

  font-family : "SFUIDisplay";
  src : url("/css/fonts/SFUIDisplay/heavy/SFUIDisplay-Heavy.eot?#iefix") format("embedded-opentype"), local("☺"), url("/css/fonts/SFUIDisplay/heavy/SFUIDisplay-Heavy.woff") format("woff"), url("/css/fonts/SFUIDisplay/heavy/SFUIDisplay-Heavy.ttf") format("truetype"), url("/css/fonts/SFUIDisplay/heavy/SFUIDisplay-Heavy.svg#SFUIDisplay") format("svg");
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-weight : 900;

    font-family : "SFUIDisplay";
    src : url("/css/fonts/SFUIDisplay/heavy/SFUIDisplay-Heavy.svg#SFUIDisplay") format("svg");
  }
}

body {
  font-weight : 400;
  font-style : normal;
  color: #000;
  font-family : "SFUIDisplay", sans-serif;
}

html {
  font-size : 14px;
  line-height : 1.37;
}