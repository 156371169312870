section.estimate{
  padding-bottom: 81px;
  padding-top: 52px;
  .wide{
    h1{
      margin-bottom: 27px;
    }
    .estimate_form{
      padding-top: 20px;
      width: 100%;
      height: auto;
      .col-md-6:first-of-type{
        flex: 0 0 51.1%;
        max-width: 51.1%;
        @media (max-width: 768px){
          flex: 0 0 100%;
          max-width: 100%;
        }
      }
      .col-md-6:last-of-type{
        flex: 0 0 48.8%;
        max-width: 48.8%;
        @media (max-width: 768px){
          flex: 0 0 100%;
          max-width: 100%;
        }
      }
      p{
        color: #000000;
        font-weight: 900;
        line-height: 14px;
        padding-left: 19px;
        margin-bottom: 5px;
        @media (max-width: 400px){
          font-size: 11px;
        }
      }
      .form-group{
        width: 100%;
        margin-bottom: 22px;
        &:last-of-type{
          margin-bottom: 10px;
        }
        @media (max-width: 400px){
          margin-bottom: 15px;
        }
      }
      .help-block{
        color: #ff2828;
        font-size: 14px;
        &.file-help-block{
          color: #000;
          font-size: 18px;
          a.delete{
            display: inline-block;
            margin-left: 4px;
            color: #ccc;
            transition: all .5s ease;
            text-decoration: none;
            &:hover{
              color: #ff2828;
            }
          }
        }
      }
      input{
        width: 100%;
        max-width: 224px;
        height: 44px;
        padding-left: 16px;
        box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.2);
        outline: none;
        border: 1px solid #cccccc;
        color: #000000;
        font-size: 18px;
        font-weight: 400;
        @media (max-width: 400px){
          max-width: 100%;
          font-size: 14px;
        }
        &[type=email]{
          max-width: 100%;
        }
      }
      textarea{
        box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.2);
        outline: none;
        border: 1px solid #cccccc;
        max-width: 100%;
        padding: 8px 16px;
        width: 100%;
        color: #000000;
        font-size: 18px;
        font-weight: 400;
        line-height: 21px;
        @media (max-width: 400px){
          font-size: 14px;
          line-height: 16px;
          max-height: 111px;
        }
      }
      button{
        width: 173px;
        display: inline-block;
        height: 48px;
        background-color: #ffde00;
        color: #000000;
        font-size: 22px;
        font-weight: 700;
        line-height: 28px;
        outline: none;
        border: none;
        cursor: pointer;
        @media (max-width: 400px){
          width: 100%;
          font-size: 14px;
          line-height: 14px;
          height: 39px;
        }
      }
      .file-reserve-group{
        width: 173px;
        margin-bottom: 0;
        float: right;
        @media (max-width: 400px){
          float: none;
          margin-bottom: 20px;
          width: 100%;
        }
        input[type=file]{
          display: none;
        }
        label{
          cursor: pointer;
          width: 100%;
          height: 48px;
          background-color: #453e42;
          color: #ffffff;
          margin-bottom: 0;
          font-size: 22px;
          text-align: center;
          font-weight: 700;
          line-height: 46px;
          position: relative;
          @media (max-width: 400px){
            height: 39px;
            font-size: 14px;
            line-height: 38px;
          }
          &::before{
            content: "";
            width: 22px;
            height: 43px;
            position: absolute;
            top: -7px;
            left: 25px;
            background: url(../img/pics/clip_icon.png) no-repeat;
          }
        }
      }
      .file-receiver{
        width: 100%;
        height: 70%;
        margin-top: 14%;
        display: flex;
        align-items: center;
        transition: all .7s ease;
        border: 2px dashed #fff;
        justify-content: center;
        @media (max-width: 768px){
          display: none;
        }
        &:hover{
          border: 2px dashed #ccc;
        }
        &.is-dragover{
          border: 2px dashed #ff2828;
          img{
            opacity: .5;
          }
        }
        img{
          width: 420px;
          max-width: 90%;
          height: auto;
        }
      }
    }
  }
}