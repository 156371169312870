section.advantages{
  margin-bottom: 60px;
  .wide{
    .row{
      .col-md-3{
        max-width: calc(25% - 6px);
        margin: 114px 2px 0;
        background-color: #f0fbff;
        @media (max-width: 990px){
          max-width: calc(50% - 4px);
          flex: 0 0 calc(50% - 4px);
          margin-top: 80px;
        }
        @media (max-width:767px){
          margin-top: 48px;
        }
        .img_container{
          position: relative;
          top: -44px;
          height: 99px;
          margin-bottom: -40px;
          @media (max-width: 768px){
            max-height: 60px;
            max-width: 68px;
            top: -26px;
            margin-bottom: -20px;
          }
          img{
            max-height: 100%;
            position: absolute;
            bottom: 0;
            max-width: 100%;
          }
        }
        p{
          color: #000000;
          font-size: 20px;
          margin-bottom: 36px;
          line-height: 26px;
          @media (max-width: 768px){
            font-size: 16px;
            line-height: 20px;
          }
          @media (max-width: 600px){
            font-size: 12px;
            line-height: 16px;
          }
          &.name{
            margin-bottom: 2px;
          }
          a{
            color: #0a84b6;
            text-decoration: underline;
            line-height: 34px;
          }
        }
      }
    }
  }
}