section.modal{
  display: none;
  position: fixed;
  width: 100vw;
  min-height: 100vh;
  background: rgba(0,0,0,.3);
  top: 0;
  left: 0;
  &.visible{
    display: block;
  }
  .content{
    width: 80%;
    border-radius: 10px;
    max-width: 785px;
    border: 1px solid #c1c1c1;
    box-shadow: 0 0 20px rgba(0,0,0,.5);
    background: white;
    height: auto;
    position: relative;
    margin: 10vh auto;
    padding: 60px 71px 66px 91px;
    @media (max-width: 768px){
      width: 95%;
      padding: 30px 20px;
    }
    a.close{
      position: absolute;
      width: 35px;
      height: 35px;
      opacity: 0.3;
      color: #000000;
      font-size: 62px;
      font-weight: 400;
      line-height: 26px;
      top: -50px;
      right: -50px;
    }
    .img_container{
      position: relative;
      width: 117px;
      height: 82px;
      @media (max-width: 768px){
        max-height: 60px;
        max-width: 68px;
      }
      img{
        max-height: 100%;
        max-width: 100%;
      }
    }
    .delivery_item {
      display: inline-block;
      vertical-align: bottom;
      //padding: 10px 15px;
      transition: all .6s ease;
      width: 32.9%;
      @media (max-width: 990px) {
        //width: 49%;
      }
      @media (max-width: 475px) {
        //padding: 10px;
      }
      &:hover {
        //background-color: #ffe71d;
        .row_item {
          p {
            //background-color: #ffe71d;
            //z-index: 2;
          }
        }
      }
      p.head {
        font-size: 22px;
        font-weight: 700;
        line-height: 26px;
        span{
          font-size: 20px;
        }
        @media (max-width: 990px) {
          //min-height: 52px;
        }
        @media (max-width: 475px) {
          //font-size: 18px;
          //line-height: 20px;
        }
      }
      .point {
        width: 100%;
        &.noted {
          .row_item {
            display: inline-block;
          }
          p.note {
            display: inline-block;
            margin-bottom: 0;
            margin-left: 1px;
            @media (max-width: 475px) {
              display: block;
              max-width: 100px;
              text-align: right;
              margin-top: -4px;
            }
          }
        }
      }
      .row_item {
        position: relative;
        background-image: url(../img/pics/dots.png);
        background-repeat: repeat-x;
        background-position: 0 40%;
        width: 105px;
        p {
          transition: all .6s ease;
          margin-bottom: 2px;
          display: inline-block;
          background: white;
          &.weight {
            max-width: 49px;
            width: auto;
            font-weight: 800;
          }
          &.price {
            float: right;
            position: relative;
            max-width: calc(100% - 47px);
          }
        }
      }
    }
  }
  &#password-change{
    .content{
      max-width: 432px;
      padding: 55px 58px 22px 58px;
      @media (max-width: 768px){
      width: 95%;
      padding: 30px 20px;
    }
      p{
        font-weight: 800;
        padding-left: 13px;
        margin-bottom: 1px;
      }
      input{
        width: 100%;
        height: 40px;
        padding-left: 16px;
        box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.2);
        outline: none;
        border: 1px solid #cccccc;
        color: #000000;
        margin-bottom: 13px;
        font-size: 18px;
        font-weight: 400;
        @media (max-width: 400px){
          font-size: 14px;
        }
      }
      button{
        max-width: 176px;
        margin: 21px auto 0;
        width: 100%;
        display: block;
        height: 48px;
        background-color: #ffde00;
        color: #000000;
        font-size: 22px;
        font-weight: 700;
        line-height: 28px;
        outline: none;
        border: none;
        cursor: pointer;
        @media (max-width: 400px){
          font-size: 14px;
          line-height: 14px;
          height: 39px;
        }
      }
    }
  }

  &#thnx{
    .content {
      max-width: 432px;
      padding: 50px 92px 47px;
      @media (max-width: 768px) {
        width: 95%;
        padding: 30px 20px;
      }
      h3{
        text-align: center;
        font-size: 22px;
        margin-bottom: 17px;
        font-weight: 800;
        &.light_vers{
          font-weight: 400;
          font-size: 20px;
          line-height: 22px;
          margin-bottom: 34px;
        }
      }
      .img_pic{
        max-width: 100px;
        margin: 0 auto;
        img{
          max-height: 84px;
        }
      }
    }
  }
}