section.woods{
  margin-bottom: 102px;
  @media (max-width: 768px){
    margin-bottom: 70px;
  }
  @media (max-width: 420px){
    //position: relative;
    &::after{
      /*content: "";
      width: 50px;
      height: 300px;
      background: linear-gradient(to right, transparent 0%, white 100%);
      position: absolute;
      top: 50px;
      right: 0;*/
    }
  }
  .row{
    justify-content: space-between;
  }
  h1{
    margin-bottom: 20px;
  }
  .col-md-7{
    margin-top: 45px;
    @media (max-width: 990px){
      flex: 0 0 100%;
      max-width: 100%;
      margin-top: 0;
    }
  }
  .grid_container{
    max-width: 100%;
    @media (max-width: 420px){
      //overflow-x: auto;
    }
  }
  .grid{
    @media (max-width: 420px){
      //min-width: 900px;
      //max-width: none;
    }
  }
  .grid_sizer2, .item_good{
    width: 42%;
    @media (max-width: 990px){
      width: 49%;
      display: inline-block;
      vertical-align: top;
    }
    @media (max-width: 420px){
      //min-width: 200px;
      width: 100%;
      max-width: 100%;
      //margin-right: 20px;
    }
  }
  .left_item{
    width: 38%;
    @media (max-width: 990px){
      width: 49%;
      display: inline-block;
      vertical-align: top;
    }
    @media (max-width: 420px){
      //min-width: 200px;
            width: 100%;
            max-width: 100%;
            //margin-right: 20px;
    }
  }
  .item_good{
    height: 153px;
    margin-bottom: 35px;
    @media (max-width: 990px){
      height: auto;
    }
    &.left_item{
      height: 341px;
      @media (max-width: 990px){
        height: auto;
      }
    }
  }
  .gutter2{
    width: 7%;
    @media (max-width: 990px){
      width: 0;
    }
  }
  .item--width3{
    width: 100%;
    //text-align: right;
    margin-bottom: 0;
    height: auto;
    @media (max-width: 990px) {
      width: 100%;
      height: auto;
    }
    @media (max-width: 500px){
      display: none;
    }
  }

  .col-md-5{
    flex: 0 0 40%;
    max-width: 40%;
    @media (max-width: 990px){
      flex: 0 0 100%;
      max-width: 100%;
      margin-top: 30px;
    }
    form{
      width: 93%;
      //width: 422px;
      //height: 874px;
      background-color: rgba(173,171,175,.2);
      border-radius: 40px;
      padding: 39px 22px 45px;
      @media (max-width: 990px){
        h3 br{
          display: none;
        }
        width: 100%;
      }
      @media (max-width: 470px){
        padding: 30px 17px 40px;
      }
      @media (max-width: 365px){
        padding: 20px 10px 30px;
      }
      .input_container{
        margin-top: 15px;
        margin-bottom: 9px;
        p{
          display: inline-block;
          color: #000000;
          font-size: 16px;
          line-height: 16px;
          @media (max-width: 470px){
            font-size: 13px;
            line-height: 15px;
          }
        }
        input{
          outline: none;
          width: 54px;
          height: 32px;
          box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.5);
          border: 1px solid #c1c1c1;
          background-color: #ffffff;
          display: inline-block;
          text-align: center;
          font-size: 25px;
          line-height: 25px;
          padding: 0;
          border-radius: 0;
          margin: 0 5px;
          -moz-appearance:textfield;
          @media (max-width: 470px){
            width: 46px;
            height: 27px;
            font-size: 21px;
          }
        }
        input[type=number]::-webkit-inner-spin-button,
        input[type=number]::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
      hr{
        height: 1.5px;
        border: none;
        background: rgba(0,0,0,1);
        margin-top: 0;
      }
      .item{
        display: block;
        margin-bottom: 6px;
        p{
          display: inline-block;
          margin-bottom: 0;
        }
        p.name{
          width: 77%;
          text-shadow: 0 1px 0 #ffffff;
          color: #000000;
          font-size: 16px;
          font-weight: 700;
          letter-spacing: 0.4px;
          line-height: 18px;
          @media (max-width: 470px){
            font-size: 13px;
            line-height: 14px;
          }
          @media (max-width: 350px){
            width: 74%;
            padding-right: 15px;
          }
          span{
            display: block;
            margin-top: 2px;
            font-weight: 400;
            font-size: 11px;
            line-height: 13px;
            border-bottom: 1px solid rgba(0,0,0,.2);
            @media (max-width: 470px){
              font-size: 9px;
              line-height: 11px;
            }
          }
        }
        p.price{
          color: #000000;
          font-size: 16px;
          line-height: 16px;
          width: calc(13% - 4px);
          @media (max-width: 470px){
            font-size: 13px;
          }
          @media (max-width: 350px){
              width: calc(15% - 4px);
            }
        }
        p.amount{
          width: calc(10% - 4px);
          font-size: 16px;
          white-space: nowrap;
          @media (max-width: 470px){
            font-size: 13px;
          }
          @media (max-width: 350px){
            width: calc(11% - 4px);
          }
        }
      }
      .amount_price{
        width: 23%;
        margin-left: 77%;
        margin-top: 19px;
        border-top: 1px solid black;
        font-weight: 700;
        white-space: nowrap;
        font-size: 16px;
        @media (max-width: 470px){
          font-size: 13px;
        }
      }
      button.buy{
        width: 100%;
        color: #000000;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        margin-top: 20px;
        height: 55px;
        border-radius: 0;
        box-shadow: 0 11px 40px #ffffff;
        background-color: #ffde00;
        @media (max-width: 470px){
          font-size: 14px;
          height: 46px;
        }
      }
    }
  }
}