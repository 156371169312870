section.ordering{
  padding-bottom: 120px;
  padding-top: 43px;
  .wide{
    .row{
      .col-md-12{
        h1{
          margin-bottom: 45px;
        }

        .table_container {
          margin-bottom: 50px;
          table {
            //min-width: 724px;
            max-width: 736px;
            width: 100%;
            border-collapse: collapse;
            @media (max-width: 990px) {
              min-width: 100%;
            }
            @media (max-width: 768px) {
              display: block;
              width: 100%;
              tbody {
                display: block;
                width: 100%;
              }
            }
            tr {
              position: relative;
              transition: all .5s ease;
              @media (max-width: 768px) {
                display: block;
                width: 100%;
                margin-bottom: 35px;
              }
              &.sum {
                font-weight: 700;
                td {
                  height: 89px;
                  @media (max-width: 768px) {
                    height: auto;
                    &.right_align {
                      text-align: left;
                      display: inline-block;
                    }
                    &.price {
                      display: inline-block;
                      border-top: 0;
                    }
                  }
                  a{
                    display: block;
                    margin-bottom: 9px;
                    text-decoration: underline #0587ba;
                    font-size: 16px;
                  }
                }
                p {
                  display: inline-block;
                }
              }
              .right_align {
                text-align: right;
                &.price {
                  @media (max-width: 768px) {
                    float: right;
                    max-width: 50%;
                    display: inline-block;
                  }
                }
                &.one_price{
                  padding-right: 35px;
                  @media (max-width: 768px) {
                    margin-left: 0;
                  }
                }
              }
              .one_price {
                padding-right: 19px;
                @media (max-width: 768px) {
                  display: inline-block;
                  width: 50%;
                  margin-left: 50px;
                  padding: 0;
                  background: none;
                }
                span{
                  display: inline-block;
                  width: 66px;
                }
              }
              .weight {
                @media (max-width: 768px) {
                  display: none;
                }
              }
              td {
                //padding-left: 5px;
                height: 70px;
                transition: all .5s ease;
                color: black;
                font-size: 20px;
                font-weight: 400;
                padding-right: 5px;
                @media (max-width: 768px) {
                  display: block;
                  height: 100%;
                }
                &.border_top {
                  position: relative;
                  &::after{
                    content: "";
                    width: 100%;
                    height: 2px;
                    background: #000;
                    top: 13px;
                    left: 0;
                    position: absolute;
                  }
                }
                &.name {
                  width: 52.1%;
                  padding-bottom: 9px;
                  background: linear-gradient(#ccc 0, white 1px);
                  background-repeat: repeat-x;
                  background-size: 2px 2px;
                  background-position: 0 68%;
                  @media (max-width: 990px) {
                    width: 44.5%;
                  }
                  @media (max-width: 768px) {
                    display: block;
                    width: calc(100% - 47px);
                    margin-left: 50px;
                    padding: 0;
                    background: none;
                  }
                  p {
                    display: block;
                    font-size: 20px;
                    font-weight: 700;
                    letter-spacing: 0.5px;
                    line-height: 20px;
                    margin-bottom: 0;
                    background: white;
                    @media (max-width: 990px) {
                      font-size: 18px;
                    }
                    @media (max-width: 768px) {
                      margin-bottom: 4px;
                      display: block;
                      background: transparent;
                    }
                    @media (max-width: 500px) {
                      font-size: 16px;
                    }
                  }
                  span {
                    display: inline-block;
                    background: white;
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: 0.35px;
                    line-height: 14px;
                    margin-bottom: 0;
                    @media (max-width: 990px) {
                      font-size: 12px;
                    }
                    @media (max-width: 500px) {
                      font-size: 11px;
                      display: block;
                      background: transparent;
                    }
                  }
                }
                &.img {
                  display: block;
                  padding-left: 0;
                  padding-right: 0;
                  @media (max-width: 768px) {
                    float: left;
                    position: absolute;
                    top: 0;
                    left: 0;
                  }
                }
                .img_container {
                  height: 45px;
                  display: flex;
                  align-items: flex-start;
                  justify-content: center;
                  width: 35px;
                  img {
                    max-width: 100%;
                    max-height: 100%;
                  }
                }
              }
            }
          }
        }

        form{
          display: block;
          h1{
            margin-bottom: 24px;
          }
          .conditions{
            display: inline-block;
            margin: 0 0 0 8px;
            font-size: 16px;
            line-height: 18px;
            border-bottom: 1px dashed #0a84b6;
            text-decoration: none;
          }
          .point{
            margin-bottom: 82px;
            input[type=radio]{
              display: inline-block;
              border: none;
              background: white;
              margin-right: 8px;
              outline: none;
              -webkit-appearance: none;
              -moz-appearance: none;
              appearance: none;
              position: relative;
              &::before{
                content: "";
                width: 24px;
                height: 24px;
                border: 2px solid #413f43;
                opacity: 0.3;
                display: inline-block;
                border-radius: 50%;
                position: relative;
                top: 6px;
                @media (max-width: 470px){
                  top: 8px;
                }
              }
            }
            input[type=radio]:checked{
              &::before{
                content: "";
                border: 8px solid #419bf9;
                opacity: 1;
              }
            }

            label{
              display: inline-block;
              margin-bottom: 6px;
              vertical-align: top;
              color: #000000;
              font-size: 20px;
              line-height: 34px;
              max-width: calc(100% - 37px);
              @media (max-width: 990px){
                font-size: 18px;
                line-height: 20px;
                margin: 9px 0;
              }
              @media (max-width: 470px){
                font-size: 15px;
                //line-height: 18px;
              }
              input{
                display: inline-block;
                height: 34px;
                outline: none;
                -moz-appearance:textfield;
                border: 1px solid #c1c1c1;
                margin: 0 3px;
                padding: 0 10px;
                color: #000000;
                font-size: 20px;
                background-color: #ffffff;
                @media (max-width: 990px){
                  height: 30px;
                  font-size: 16px;
                }
                &.distance{
                  width: 69px;
                }
                &.floor{
                  width: 47px;
                }
              }
              input[type=number]::-webkit-inner-spin-button,
              input[type=number]::-webkit-outer-spin-button {
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                margin: 0;
              }
            }

            input[type=checkbox]{
              border: none;
              background: white;
              margin-right: 31px;
              outline: none;
              -webkit-appearance: none;
              -moz-appearance: none;
              appearance: none;
            }
            input[type=checkbox] + label{
              position: relative;
              &::before{
                content: "";
                width: 21px;
                height: 21px;
                border: 2px solid rgba(0,0,0,.2);
                background: white;
                position: absolute;
                top: 7px;
                left: -31px;
              }
            }
            input[type=checkbox]:checked + label{
              &::after{
                content: "";
                background: url(../img/pics/ok_icon.png) no-repeat;
                width: 20px;
                height: 16px;
                position: absolute;
                top: 8px;
                left: -27px;
              }
            }

            .checkbox-group{
              margin-top: 22px;
            }
          }

          .user_data{
            margin-top: 85px;
            max-width: 590px;
            @media (max-width: 990px){
              max-width: 100%;
            }
            p{
              color: #000000;
              font-weight: 900;
              line-height: 14px;
              padding-left: 19px;
              margin-bottom: 5px;
              @media (max-width: 550px){
                font-size: 11px;
              }
            }
            .form-group{
              width: 100%;
              margin-bottom: 23px;
              &:last-of-type{
                margin-bottom: 10px;
              }
              @media (max-width: 550px){
                margin-bottom: 15px;
              }
            }
            .help-block{
              color: #ff2828;
              font-size: 14px;
              &.file-help-block{
                color: #000;
                font-size: 18px;
                a.delete{
                  display: inline-block;
                  margin-left: 4px;
                  color: #ccc;
                  transition: all .5s ease;
                  text-decoration: none;
                  &:hover{
                    color: #ff2828;
                  }
                }
              }
            }
            input{
              width: 100%;
              max-width: 224px;
              height: 44px;
              padding-left: 16px;
              box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.2);
              outline: none;
              border: 1px solid #cccccc;
              color: #000000;
              font-size: 18px;
              font-weight: 400;
              @media (max-width: 550px){
                max-width: 100%;
                font-size: 14px;
              }
              &[type=email], &.maxwidth{
                max-width: 100%;
              }
            }
            textarea{
              box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.2);
              outline: none;
              border: 1px solid #cccccc;
              max-width: 100%;
              padding: 8px 16px;
              width: 100%;
              color: #000000;
              font-size: 18px;
              font-weight: 400;
              line-height: 21px;
              @media (max-width: 550px){
                font-size: 14px;
                line-height: 16px;
                max-height: 111px;
              }
            }
            button{
              display: inline-block;
              background-color: #ffde00;
              height: 54px;
              color: #000000;
              outline: none;
              border: none;
              cursor: pointer;
              &.send{
                width: 233px;
                margin-top: 9px;
                font-size: 20px;
                font-weight: 700;
                line-height: 28px;
                @media (max-width: 550px){
                  width: 100%;
                  font-size: 14px;
                  line-height: 14px;
                  height: 39px;
                }
              }
              &.input_from_yandex{
                width: 254px;
                float: right;
                height: 44px;
                color: #000000;
                font-size: 16px;
                font-weight: 400;
                margin-top: 20px;
                line-height: 14px;
                @media (max-width: 550px){
                  width: 100%;
                  font-size: 14px;
                  line-height: 14px;
                  height: 39px;
                  float: none;
                  margin-bottom: 20px;
                }
                img{
                  display: inline-block;
                  vertical-align: bottom;
                  width: 50px;
                  height: 18px;
                }
              }
            }
            p.hint{
              font-weight: 400;
              font-size: 14px;
              line-height: 18px;
              padding-left: 0;
              display: inline-block;
              vertical-align: bottom;
              position: relative;
              top: 9px;
              margin-left: 18px;
              max-width: 122px;
              letter-spacing: 0.14px;
              @media (max-width: 550px){
                max-width: 100%;
                margin-left: 0;
                font-size: 11px;
                line-height: 13px;
              }
              a{
                text-decoration: underline #0a84b6;
              }
            }
          }
        }
      }
    }
  }
}