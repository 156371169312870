section.phone_number{
  background-image: linear-gradient(to right, #ffde00 50%, white 50%, white 100%);
  @media (max-width: 600px){
    background: #ffde00;
  }
  .wide{
    .row{
      min-height: 140px;
      align-items: center;
      margin-right: 1px;
      justify-content: center;
      background-color: #ffde00;
      position: relative;
      @media (max-width: 600px){
        min-height: 114px;
        margin-right: -10px;
      }
      &::after{
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 71px 31px 71px 0;
        border-color: transparent #ffffff transparent transparent;
        position: absolute;
        top: -2px;
        right: 0;
        @media (max-width: 600px){
          display: none;
        }
      }
      .col-md-8{
        text-align: center;
      }
      a{
        color: #000000;
        font-size: 84px;
        line-height: 84px;
        text-decoration: none;
        position: relative;
        display: inline-block;
        @media (max-width: 1230px){
          font-size: 60px;
          line-height: 60px;
        }
        @media (max-width: 990px){
          font-size: 50px;
          line-height: 50px;
        }
        @media (max-width: 600px){
          font-size: 42px;
        }
        @media (max-width: 360px){
          font-size: 30px;
        }
        &::after{
          content: "Наш менеджер ответит на вопросы и поможет найти то, что нужно";
          font-size: 14px;
          line-height: 17px;
          top: 10px;
          right: -164px;
          width: 129px;
          text-align: left;
          height: auto;
          position: absolute;
          @media (max-width: 1230px){
            top: -11px;
          }
          @media (max-width: 990px){
            width: 100%;
            height: auto;
            position: relative;
            display: block;
            text-align: center;
            top: auto;
            right: auto;
          }
          @media (max-width: 600px){
            max-width: 200px;
            font-size: 12px;
            line-height: 14px;
            margin: 0 auto;
          }
        }
      }
    }
  }
}