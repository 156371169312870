.row.how_to_choose{
  margin-bottom: 100px;
  margin-top: 91px;
  @media (max-width: 768px){
    margin-bottom: 5vh;
    margin-top: 4vh;
  }
  .col-md-9{
    @media (max-width: 991px){
      max-width: 100%;
      flex: 0 0 100%;
    }
  }
  ol{
    margin-top: 43px;
    margin-bottom: 52px;
    list-style-type: none;
    counter-reset: myCounter;
    padding-left: 48px;
    li{
      position: relative;
      &::before{
        content: counter(myCounter);
        counter-increment: myCounter;
        font-size: 54px;
        font-weight: 700;
        line-height: 22px;
        position: absolute;
        top: 19px;
        left: -50px;
      }
    }
  }
  h1{
    margin-bottom: 22px;
  }
  h3{
    margin-bottom: 34px;
  }
  .col-md-3{
    padding-left: 59px;
    margin-bottom: 23px;
    align-self: flex-end;
    @media (max-width: 991px){
      flex: 0 0 100%;
      max-width: 100%;
      padding-left: 15px;
    }
    @media (max-width: 768px){
      padding-left: 10px;
      margin-bottom: 0;
    }
    strong{
      display: block;
      margin-bottom: 3px;
    }
    p{
      line-height: 20px;
    }
  }
}