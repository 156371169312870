footer.main{
  background-color: #dff5ff;
  .wide{
    .col-md-4, .col-md-2{
      padding-top: 29px;
      padding-bottom: 23px;
      @media (max-width: 990px){
        max-width: 33%;
        flex: 0 0 33%;
      }
    }
    .col-md-4:first-of-type{
      flex: 0 0 34.333333%;
      max-width: 34.333333%;
      @media (max-width: 1320px){
        flex: 0 0 50%;
        max-width: 50%;
      }
      @media (max-width: 990px){
        max-width: 33%;
        flex: 0 0 33%;
      }
      @media (max-width: 576px){
        flex: 0 0 50%;
        max-width: 50%;
      }
      @media (max-width: 349px){
        flex: 0 0 100%;
        max-width: 100%;
        text-align: center;
      }
    }
    .col-md-4:nth-child(2){
      flex: 0 0 32.333333%;
      max-width: 32.333333%;
      padding-left: 5%;
      @media (max-width: 576px){
        flex: 0 0 50%;
        max-width: 50%;
        display: flex;
        justify-content: space-around;
      }
      @media (max-width: 349px){
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
    .col-md-2:nth-child(3){
      @media (max-width: 1320px){
        display: none;
      }
    }
    .col-md-2:last-of-type{
      @media (max-width: 576px){
        flex: 0 0 100%;
        max-width: 100%;
        text-align: center;
      }
      @media (max-width: 349px){
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
    .row{
      a{
        display: block;
        color: #3d6276;
        text-decoration: underline;
      }
      p{
        color: #000000;
        margin-bottom: 10px;
      }
      .map_block{
        margin-right: 24px;
        display: inline-block;
        @media (max-width: 990px){
          margin-bottom: 20px;
        }
        @media (max-width: 576px){
          display: none;
        }
        #map{
          width: 179px;
          height: 94px;
          display: block;
          margin-bottom: 7px;
        }
      }
      .contacts_block{
        max-width: 175px;
        display: inline-block;
        vertical-align: top;
        @media (max-width: 576px){
          font-size: 12px;
          line-height: 16px;
          max-width: 100%;
        }
        p:first-of-type{
          line-height: 14px;
          margin-bottom: 11px;
        }
        p{
          margin-bottom: 7px;
        }
      }
      nav{
        display: inline-block;
        @media (max-width: 768px){
          margin-left: 30px;
        }
        @media (max-width: 576px){
          margin-left: 0;
        }
        &:first-of-type{
          margin-right: 39px;
          @media (max-width: 576px){
            margin-right: 0;
          }
        }
        a{
          line-height: 24.5px;
          padding-left: 5px;
          @media (max-width: 576px){
            font-size: 13px;
          }
          &.sale{
            position: relative;
            background: white;
            &::after{
              content: "";
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 12px 8px 12px 0;
              border-color: transparent #dff5ff transparent transparent;
              position: absolute;
              top: 0;
              right: -1px;
            }
          }
        }
      }
      .col-md-2{
        @media (max-width: 576px){
          font-size: 11px;
        }
        &:last-of-type{
          border-left: 1px solid white;
          padding-left: 1.85%;
        }
        a{
          display: inline-block;
          line-height: 25px;
          @media (max-width: 990px){
            line-height: inherit;
          }
        }
        p{
          display: inline-block;
          vertical-align: bottom;
          margin-bottom: 0;
          &:first-of-type{
            margin-bottom: 10px;
          }
        }
        br.shown{
          @media (min-width: 576px){
            display: none;
          }
        }
        @media (max-width: 576px){
          br.hidden{
            display: none;
          }
        }
      }
    }
  }
}