section.navigation{
  background-image: linear-gradient(to right, #ffde00 85%, white 85%, white 100%);
  min-height: 50px;
  .wide{
    max-height: 50px;
    @media (max-width: 990px) {
      //position: relative;
      &::after {
        //content: "";
        //width: 70px;
        //height: 100%;
        //background: linear-gradient(to right, transparent 0%, #ffde00 100%);
        //position: absolute;
        //top: 0;
        //right: 15px;
      }
    }
    @media (max-width: 768px){
      &::after{
        right: 0;
      }
    }
    nav{
      max-width: 100%;
      @media (max-width: 990px){
        //overflow-x: auto;
        //overflow-y: hidden;
      }
    }
    .row{
      align-items: stretch;
    //min-width: 1080px;
      margin-right: -14px;
      max-width: 100%;
      @media (max-width: 990px){
        min-height: 50px;
        justify-content: space-between;
      }
      a{
        color: #000000;
        font-size: 16px;
        line-height: 20px;
        transition: all .5s ease;
        text-decoration: none;
        white-space: nowrap;
        display: inline;
        //margin-right: 24px;
        @media (max-width: 1230px){
          font-size: 15px;
          //margin-right: 15px;
        }
        &.sale{
          color: #ffffff;
        }

      }
      .col-md-11{
        background: #ffde00;
        position: relative;
        @media (max-width: 991px){
          flex: 0 0 45%;
          max-width: 45%;
          padding: 13.5px 15px;
          a{
            display: none;
            &:first-of-type{
              display: inline-block;
            }
          }
          .burger{
            display: inline-block;
          }
        }
        a{
          display: inline-block;
          padding: 15px 14px;
          margin-left: -4px;
          @media (max-width: 1230px){
            padding: 15px 7px;
          }
          &:first-of-type{
            margin-left: 0;
          }
          &:hover, &.active{
            background-color: #413f43;
            color: #ffffff;
          }
        }
        .submenu{
          position: absolute;
          padding-top: 25px;
          width: 260px;

          display: none;
          max-height: 0;
          z-index: 99;
          background-color: #413f43;
          transition: all .5s ease;
          &.visible{
            display: block;
            min-height: 413px;
          }

          &.level1{
            background-color: #413f43;
            top: 50px;
            left: 15px;
            a{
              color: #ffffff;
              &:hover, &:active, &.active{
                background-color: #e5e1e5;
                color: #000;
              }
            }
            &::after{
              content: "";
              background: url(../img/pics/tools.png) no-repeat;
              width: 48px;
              height: 57px;
              bottom: 24px;
              left: 16px;
              position: absolute;
            }
          }

          &.level2{
            background-color: #e5e1e5;
            top: 0;
            left: 260px;
            a{
              color: #000;
              &:hover, &:active, &.active{
                background-color: #ffffff;
              }
            }
          }

          &.level3{
            background-color: #ffffff;
            top: 0;
            left: 260px;
            a{
              color: #000;
              &:hover, &:active, &.active{
                background-color: #ffde00;
              }
            }
          }

          a{
            display: block;
            color: #ffffff;
            padding: 5px 35px 6px 14px;
            margin-left: 0;
            font-size: 15px;
          }
        }
        .burger{
          display: none;
          background: url(../img/pics/burger_icon_dark.png) no-repeat;
          width: 20px;
          height: 16px;
          position: relative;
          top: 3px;
          margin-right: 10px;
          .content{
            z-index: -100;
            opacity: 0;
            transition: all .5s ease;
            a{
              display: none;
            }
          }
          &.open{
            background: #ffde00;
            width: 100vw;
            min-height: 100vh;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 999;
            .content{
              padding-top: 40px;
              opacity: 1;
              a{
                display: block;
                max-width: 130px;
                margin: 10px auto;
                white-space: normal;
                text-align: center;
                &.close{
                  text-transform: none;
                  border-radius: 50%;
                  border: 1px solid #000;
                  position: absolute;
                  top: 20px;
                  right: 20px;
                  margin-bottom: 0;
                  font-size: 15px;
                  line-height: 10px;
                  text-align: center;
                  color: #000;
                  padding: 6px 8px 9px;
                }
              }
            }
          }
        }
      }
      .col-md-1{
        padding: 14.5px 20px;
        background-color: #ff2828;
        width: calc(8.33% - 1px);
        max-width: calc(8.33% - 1px);
        flex: 0 0 calc(8.33% - 1px);
        position: relative;
        @media (max-width: 991px){
          flex: 0 0 12%;
          max-width: 12%;
          padding: 14.5px 15px;
          min-width: 90px;
        }
        @media (max-width: 768px){
          //max-width: calc(8.33% - 1px);
          //flex: 0 0 calc(8.33% - 1px);
          //padding: 15px;
        }
        &::after{
          content: "";
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 26px 10px 26px 0;
          border-color: transparent #ffffff transparent transparent;
          position: absolute;
          top: -1px;
          right: -1px;
          @media (max-width: 990px){
            //border-color: transparent #ffde00 transparent transparent;
          }
        }
      }
    }
  }
}